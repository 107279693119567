import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import { userState } from "../../interface/MainInterface";
import { HttpCustomApi } from "../../interface/custom-api";
import { appConfig } from "../../config/Config";

interface propsType {
  userState: userState;
}

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

const VisaConfirmComponent = (props: propsType) => {
  const navigate = useNavigate();
  const [visaConfirmList, setVisaConfirmList] = useState<any[]>([]);

  const setVisaConfirmInfoList = async () => {
    const param: any = {
      command: "get_information_proj",
      pk: "data#visa",
      projection: 'sk, country, title, day, time, status, prev_status, content, new_content'
    };
  
    const res = await visaApi.common_request("adminMain", param);
  
    if (res.code === "200") {
      let myList: any = [];
      let useList: any = [];

      for (const myask of res.response.result_list) {
        myList.push(myask);
      }

      for (let aidata of myList) {
        if (aidata.status === "200" || aidata.status === "201") {
          if (aidata.status === "200") {
            aidata['flag'] = true;
          } else if (aidata.status === "201") {
            aidata['flag'] = false;
          }
          useList.push(aidata);
        }
      }

      // 정렬
      useList.sort((a: any, b: any) => {
        if (a.day > b.day) return -1;
        if (a.day < b.day) return 1;
        // day가 같다면 time으로 정렬
        if (a.time > b.time) return -1;
        if (a.time < b.time) return 1;
        return 0;
      });
      // console.log(useList);

      setVisaConfirmList(useList);
    }
  };

  useEffect(() => {
    setVisaConfirmInfoList();
  }, []);

  return (
    <div className="dashboard confirmDash">
      {props.userState.isAdmin? (
        <>
          <div className="tit">
            <h2>비자 정보 연동 결재 내역</h2>
            <div className="rightBtnArea">
              <a className="btnMore"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/aidata`)
              }}>
                더보기
              </a>
            </div>
          </div>
          <ul className="noticeList">
            {visaConfirmList.slice(0, 9).map((item: any, index) => (
              <li
                key={index}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/aidataRead/${item.sk}`, {state:{selectedItem:item}})
                }}
              >
                {item.flag ? (
                  <span className="label blue">승인</span>
                ) : (
                  <span className="label red">반려</span>
                )}
                <a>{item.country} {item.title}</a>
                <span className="date">{item.day}</span>
              </li>
            ))}
          </ul>
        </>
      ):(
        <></>
      )}
    </div>
  );
};

export default VisaConfirmComponent;
