import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import AWS from "aws-sdk";
import { saveAs } from "file-saver";

import { Box, Button, Stack, Modal, Typography, IconButton, Tooltip } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Toast from "../Toast";

interface propsType {
  rootBucket: string | undefined;
  files: any;
  canDelete: boolean;
  isBox: boolean;
  callback: any;
}

AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const AttachFileViewer = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const s3 = new AWS.S3();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  const AttachButton = (param: any) => {
    const { fileInfo, index } = param;
    return (
      <li className={`attachFlie ${fileInfo.FILE_EXT}`}>
        <a className="fileName"
          style={props.canDelete ? {} : { cursor: 'pointer' }}
          key={`file-${index}`}
          onClick={props.canDelete ? () => { } : () => { fncDownloadAttach(fileInfo) }}
        >
          {fileInfo.FILE_NAME}
          {isNaN(fileInfo.FILE_SIZE) ? (
            <span className="fileInfo">- KB
              <i className="date">{fileInfo.attach_range}</i>
            </span>
          ) : (
            <span className="fileInfo">{Math.floor(fileInfo.FILE_SIZE / 1024)}KB
              <i className="date">{fileInfo.attach_range}</i>
            </span>
          )}
        </a>
        {props.canDelete ? (
          <a className="btnDelete"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (window.confirm("첨부파일을 삭제합니다.")) {
                const callbackParam: any = {
                  command: "delete",
                  fileInfo: fileInfo,
                };
                props.callback(callbackParam);
              }
            }}
          >삭제</a>
        ) : (
          <></>
        )}
      </li>
      // <li className="attachFlie xls">
      //   <Button
      //     className="fileName"
      //     key={`file-${index}`}
      //     variant="text"
      //     sx={{
      //       justifyContent: "flex-start",
      //       textAlign: "left",
      //       textOverflow: "ellipsis",
      //       whiteSpace: "nowrap",
      //       overflow: "hidden",
      //       display: "block",
      //     }}
      //     size="small"
      //     fullWidth
      //     onClick={() => {
      //       fncDownloadAttach(fileInfo);
      //     }}
      //   >
      //     {fileInfo.FILE_NAME}
      //     <span>{Math.floor(fileInfo.FILE_SIZE / 1024)}KB {fileInfo.attach_range}</span>
      //   </Button>
      //   {props.canDelete ? (
      //     <Button
      //       className="btnDelete"
      //       sx={{ width: "30px", color: "#ff000070" }}
      //       onClick={() => {
      //         if (window.confirm("첨부파일을 삭제합니다.")) {
      //           const callbackParam: any = {
      //             command: "delete",
      //             fileInfo: fileInfo,
      //           };
      //           props.callback(callbackParam);
      //         }
      //       }}
      //       size="small"
      //     >
      //     </Button>
      //   ) : (
      //     <></>
      //   )}
      // </li>
    );
  };

  const fncDownloadAttach = async (attachInfo: any) => {
    const url_params = {
      Bucket: props.rootBucket,
      Key: attachInfo.FILE_PATH,
      Expires: 1200,
    };
    let downloadUrl;

    if (isNaN(attachInfo.FILE_SIZE)) {
      downloadUrl = attachInfo.DOWNLOAD_URL;
      // console.log(downloadUrl);
      // HTTPS로 시작하는지 확인
      if (!downloadUrl.toLowerCase().startsWith('https://')) {
        // 만약 HTTPS로 시작하지 않으면, HTTP를 HTTPS로 변경
        downloadUrl = downloadUrl.replace(/^http:\/\//i, 'https://');
      }
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.click();
    }
    else {
      downloadUrl = await s3.getSignedUrl('getObject', url_params);

      await fetch(downloadUrl, { method: "GET" })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          saveAs(blob, `${attachInfo.FILE_NAME}`);
        })
        .catch((err) => {
          console.error("err : ", err);
        });
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Box
        className={
          props.isBox === true
            ? "attachD boxSt"
            : "attachD"
        }
      >
        <p className="tit">첨부파일
          <span className="number"><i>{props.files?.length}</i> 개</span>
        </p>
        <Box className="scroll">
          <ul className="attachList">
            {props.files?.map((fileInfo: any, index: number) => (
              <AttachButton key={`attach-file-${index}`} fileInfo={fileInfo} index={index} />
            ))}
          </ul>
        </Box>
      </Box>
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(AttachFileViewer);
