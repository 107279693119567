import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const CountryRecentQnaFive = () => {
  return (
    <Box className="qTop5" sx={{ width: "100%" }}>
      <Typography className="fontB">
        최근 1개월 상담질문
        <Typography
          component="span"
          className="fontDeepBlue fontB"
          sx={{ ml: 0.5 }}
        >
          Top 5
        </Typography>
      </Typography>
      <List className="rankD" sx={{ padding: "0px" }}>
        {[
          "중국 상용 비자 발급 시 평균 소요 일정과 비용을 알려주세요.",
          "인도 출장 시 필요한 서류가 무엇이 있나요 ?",
          "도착비자 진행 절차와 출국 전 준비해야하는 서류를 설명해주세요.",
          "미국 애틀란타로 2년 출장을 가는 경우 ESTA와 상용 출장 비자 중 어떤 것을 발급받아야 하나요 ? 발급받아야 하는 비자의 준비 서류는 무엇이 있나요 ?",
          "인도네시아 상용비자 발급 시 비용을 알려주세요.",
        ].map((question, index) => (
          <ListItem key={index} className="ranking">
            <Typography component="span" className="question">
              {question}
            </Typography>
            {/* <Typography
              component="span"
              className="qNum fontDeepBlue fontB"
              sx={{ mr: 0.5 }}
            >
              000
            </Typography>
            건 */}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CountryRecentQnaFive;
