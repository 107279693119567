import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Box, Button, Stack } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import dayjs, { Dayjs } from "dayjs";

import { userState } from "../../interface/MainInterface";
import { iNotice } from '../../interface/VisaInterface'
import { useNavigate } from 'react-router-dom'
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { countries } from "../components/CountryList";
import NoticeEditor from './NoticeEditor';
import { readLocal, saveLocal } from '../../utils/localstorage_utils'
import LoadingCircle from "../../utils/LoadingCircle";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  userState: userState;
}

const PAGING_SIZE = 5                               // 화면에 보여줄 페이징 수
const NOTICE_PER_PAGE = 10                          // 한 화면에 보여줄 글 개수
const NOTICE_CNT = PAGING_SIZE * NOTICE_PER_PAGE      // 한번 ddb에서 가져올 글 개수
// 토탈개수



const NoticePage = (props: propsType) => {

  const navigate = useNavigate()
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)

  const [noticeList, setNoticeList] = useState<iNotice[]>([])
  const [notice, setNotice] = useState<iNotice | undefined>()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchKey, setSearchKey] = useState("")
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(true);

  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().add(-365, "day"));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  const [isInitialSet, setIsInitialSet] = useState(false);

  const changeSearchKey = (e: any) => {
    setSearchKey(e)
  }

  const handleKeyDown = () => {
    getNoticeList();
  }

  // ddb에서 게시글 가져오는데 날짜 1년 넣기
  const getNoticeList = async () => {

    let localSearchkey = searchKey
    // localstorage가 있으면
    if (readLocal(appConfig.noticeSearchKey01)) {
      localSearchkey = readLocal(appConfig.noticeSearchKey01) ?? "";
      setSearchKey(localSearchkey)
      saveLocal(appConfig.noticeSearchKey01, "")
    }

    const getDateDifference = (fromDate: any, toDate: any): number => {
      const from = dayjs(fromDate);
      const to = dayjs(toDate)
      return to.diff(from, 'day');
    }
    const differenceInDays = getDateDifference(fromDate, toDate);
    if (differenceInDays > 365) {
      alert("1년 이상 조건 검색하실 수 없습니다.")
      return;
    }

    const noticelistparam: any = {
      command: "get_information_proj_sk",
      pk: "notice#visa",
      projection: "pk, sk, country, title, hidden_yn, editor, update_dt",
      from_date: dayjs(fromDate).format("YYYYMMDD"),
      to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      searchKey: localSearchkey,
    };

    const visares = await visaApi.common_request("adminMain", noticelistparam);
    if (visares.code === "200") {
      let myList: any = [];
      for (const notice of visares.response.result_list) {
        if (localSearchkey === "") {
          myList.push(notice)
        }
        else if (notice.title.includes(localSearchkey)) {
          myList.push(notice)
        }
      }
      if (myList.length === 0) {
        alert("검색결과가 없습니다.")
      }
      else {
        // 정렬
        const sortedList = [...myList].sort((a, b) => {
          if (a.update_dt < b.update_dt) return 1;
          if (a.update_dt > b.update_dt) return -1;
          return 0;
        });
        setNoticeList(sortedList);
        setCurrentPage(1);
      }
    }
    else if (visares.code === "300") {
      console.log(" 300 visares.response : ", visares.response)
      alert("검색결과가 없습니다.")
    }
    else if (visares.code === "500") {
      console.log(" 500 visares.response : ", visares.response)
    }
    setIsInitialSet(true);
  }

  // 페이지 변경 핸들러
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const toggleIsComponetVisible = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  useEffect(() => {
    getNoticeList()
  }, [])


  return (
    <>
      <Box id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
        <div id="contents">
          <div className="secCont board">
            {isComponentVisible ? (
              <>
                <div className="contBody">
                  <div className="contHeader">
                    <div className="tit">
                      <p>중요 공지 사항</p>
                    </div>
                  </div>
                  <div className="contBox">
                    <div className="boardInfo">
                      <div className="info">
                        <dl className="total number">
                          <dt className="fontGray">Total</dt>
                          <dd className="fontBlue fontB">{noticeList.length}</dd>
                        </dl>
                      </div>

                      <Box className="rightbtnArea" >
                        <Box sx={{ position: "relative", right: "1px" }}>
                          <Stack direction={"row"} spacing={2} sx={{ alignItems: "center", ".MuiStack-root": { overflow: "unset" } }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthShort: "M" }}>
                              <DemoContainer components={["DatePicker", "DatePicker"]} >
                                <DatePicker
                                  showDaysOutsideCurrentMonth
                                  format="YYYY-MM-DD"
                                  value={fromDate}
                                  onChange={(newValue) => setFromDate(newValue)}
                                  sx={{ ".MuiInputBase-root": { height: "34px" }, "input[type='text']:focus": { border: "0px" } }}
                                />
                                <DatePicker
                                  showDaysOutsideCurrentMonth
                                  format="YYYY-MM-DD"
                                  value={toDate}
                                  onChange={(newValue) => setToDate(newValue)}
                                  sx={{ ".MuiInputBase-root": { height: "34px" }, "input[type='text']:focus": { border: "0px" } }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <input
                              value={searchKey}
                              className="search"
                              id="search"
                              name="search"
                              placeholder="검색어를 입력해주세요."
                              style={{ height: "40px" }}
                              onChange={(e) => { changeSearchKey(e.target.value) }}
                              autoComplete="off"
                              onKeyDown={(e) => { if (e.key === 'Enter' && !e.nativeEvent.isComposing) handleKeyDown() }}
                            />
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => {
                                getNoticeList();
                              }}
                              sx={{ borderRadius: "7px", width: "30px", height: "40px" }}
                            >
                              <SearchIcon />
                            </Button>
                            {props.userState.isAdmin ? (
                              <a className="btnLineGray new" style={{ height: "40px", paddingLeft: "30px", fontSize: "15px", cursor: "pointer" }} onClick={toggleIsComponetVisible} >신규등록</a>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </Box>

                      </Box>
                    </div>
                    <table className="boardD">
                      {props.userState.isAdmin ? (
                        <>
                          <colgroup>
                            <col className="num" />
                            <col className="country" />
                            <col className="title" />
                            <col className="see" />
                            <col className="writer" />
                            <col className="date" />
                          </colgroup>
                          <thead>
                            <tr >
                              <td scope="col">번호</td>
                              <td scope="col">국가</td>
                              <td scope="col">제목</td>
                              <td scope="col">메인노출</td>
                              <td scope="col">담당자</td>
                              <td scope="col">등록일</td>
                            </tr>
                          </thead>
                          <tbody>
                            {noticeList.slice(((currentPage - 1) * NOTICE_PER_PAGE), ((currentPage - 1) * NOTICE_PER_PAGE) + NOTICE_PER_PAGE).map((notice: any, index) => {
                              const foundCountry = countries.find(country => country.label === notice.country);
                              if (foundCountry) {
                                return (
                                  <tr onClick={() => {
                                    saveLocal(appConfig.noticeSearchKey01, searchKey)
                                    navigate(`/noticelistread/${notice.sk}`)
                                  }}
                                    key={index}>
                                    <td>{noticeList.length - (index + (NOTICE_PER_PAGE * (currentPage - 1)))}</td>
                                    <td className="bCountry">
                                      <span><img src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`} /></span>
                                      <span>{notice.country}</span>
                                    </td>
                                    <td className="boardtit">
                                      <a >{notice.title}</a>
                                    </td>
                                    <td className={notice.hidden_yn === "Y" ? "fontBlue" : "blind"}><span >노출</span></td>{/* 미노출 시 class="blind" 추가 */}
                                    <td>{notice.editor}</td>
                                    <td>{notice.sk.substring(0, 4)}-{notice.sk.substring(4, 6)}-{notice.sk.substring(6, 8)}</td>
                                  </tr>
                                )
                              }
                              // 임시코드 - 국가코드 없는 나라
                              else {
                                return (
                                  <tr onClick={() => {
                                    saveLocal(appConfig.noticeSearchKey01, searchKey)
                                    navigate(`/noticelistread/${notice.sk}`)
                                  }}
                                    key={index}>
                                    <td>{noticeList.length - (index + (NOTICE_PER_PAGE * (currentPage - 1)))}</td>
                                    <td className="bCountry" style={{ display: 'flex', alignItems: 'center' }}>
                                      <Avatar style={{ width: 24, height: 24, marginRight: 3 }}>
                                        <span style={{ fontSize: 16, alignItems: 'center' }}>{notice.country.charAt(0)}</span>
                                      </Avatar>
                                      <span>{notice.country}</span>
                                    </td>
                                    <td className="boardtit">
                                      <a >{notice.title}</a>
                                    </td>
                                    <td className={notice.hidden_yn === "Y" ? "fontBlue" : "blind"}><span >노출</span></td>{/* 미노출 시 class="blind" 추가 */}
                                    <td>{notice.editor}</td>
                                    <td>{notice.sk.substring(0, 4)}-{notice.sk.substring(4, 6)}-{notice.sk.substring(6, 8)}</td>
                                  </tr>
                                )
                              }
                            })}
                          </tbody>
                        </>
                      ) : (
                        <>
                          <colgroup>
                            <col className="num" />
                            <col className="country" />
                            <col className="title" />
                            <col className="writer" />
                            <col className="date" />
                          </colgroup>
                          <thead>
                            <tr >
                              <td scope="col">번호</td>
                              <td scope="col">국가</td>
                              <td scope="col">제목</td>
                              <td scope="col">담당자</td>
                              <td scope="col">등록일</td>
                            </tr>
                          </thead>
                          <tbody>
                            {noticeList.slice(((currentPage - 1) * NOTICE_PER_PAGE), ((currentPage - 1) * NOTICE_PER_PAGE) + NOTICE_PER_PAGE).map((notice: any, index) => {
                              const foundCountry = countries.find(country => country.label === notice.country);
                              if (foundCountry) {
                                return (
                                  notice.hidden_yn === "Y" ? (
                                    <tr onClick={() => {
                                      saveLocal(appConfig.noticeSearchKey01, searchKey)
                                      navigate(`/noticelistread/${notice.sk}`)
                                    }}
                                      key={index}>
                                      <td>{noticeList.length - (index + (NOTICE_PER_PAGE * (currentPage - 1)))}</td>
                                      <td className="bCountry">
                                        <span><img src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`} /></span>
                                        <span>{notice.country}</span>
                                      </td>
                                      <td className="boardtit">
                                        <a >{notice.title}</a>
                                      </td>
                                      <td>{notice.editor}</td>
                                      <td>{notice.sk.substring(0, 4)}-{notice.sk.substring(4, 6)}-{notice.sk.substring(6, 8)}</td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )
                                )
                              }
                              // 임시코드 - 국가코드 없는 나라
                              else {
                                return (
                                  notice.hidden_yn === "Y" ? (
                                    <tr onClick={() => {
                                      saveLocal(appConfig.noticeSearchKey01, searchKey)
                                      navigate(`/noticelistread/${notice.sk}`)
                                    }}
                                      key={index}>
                                      <td>{noticeList.length - (index + (NOTICE_PER_PAGE * (currentPage - 1)))}</td>
                                      <td className="bCountry" style={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar style={{ width: 24, height: 24, marginRight: 3 }}>
                                          <span style={{ fontSize: 16, alignItems: 'center' }}>{notice.country.charAt(0)}</span>
                                        </Avatar>
                                        <span>{notice.country}</span>
                                      </td>
                                      <td className="boardtit">
                                        <a >{notice.title}</a>
                                      </td>
                                      <td>{notice.editor}</td>
                                      <td>{notice.sk.substring(0, 4)}-{notice.sk.substring(4, 6)}-{notice.sk.substring(6, 8)}</td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )
                                )
                              }
                            })}
                          </tbody>
                        </>
                      )}
                    </table>
                    <div className="pagination">
                      <Pagination
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                            {...item}
                          />
                        )}
                        size="large"
                        className="page"
                        count={Math.ceil(noticeList.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                      />
                    </div>

                    {/* <div className="pagination">
                      <a className="btnpMove prev" style={{ cursor: 'pointer' }}>이전</a>
                      <ul className="page">
                        {[Array(5).keys()].map((_, index) => {
                          if (currentPage == index) {
                            return (
                              <li><a className="pnum on">{index + 1}</a></li>
                            )
                          }
                          else {
                            return (
                              <li><a className="pnum">{index + 1}</a></li>
                            )
                          }
                        })}

                      </ul>
                      <a className="btnpMove next" style={{ cursor: 'pointer' }}>다음</a>
                    </div> */}



                  </div>
                </div>
              </>
            ) : (
              <NoticeEditor
                propNotice={notice} // 신규등록이니까 빈값보내는게 맞음
                onChange={toggleIsComponetVisible}
                userState={props.userState}
              />
            )
            }

            {/* // 세부정보 읽기 */}
          </div>
        </div>{/*// contents */}
      </Box > {/*// container */}
      <LoadingCircle loading={!isInitialSet} />
    </>
  )

};

export default NoticePage;
