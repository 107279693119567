import { useEffect, useState } from "react";
import { userState } from "../../interface/MainInterface";
import { useNavigate } from "react-router-dom";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { countries } from "../components/CountryList";
import { readLocal, saveLocal } from "../../utils/localstorage_utils";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { setServers } from "dns";
import LoadingCircle from "../../utils/LoadingCircle";
import CircleGraph from "../components/CircleGraph";
import { ExcelDownloader } from "../../utils/attach/ExcelDownloader";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);


interface propsType {
  userState: userState;
}

const AiMeasurePage = (props: propsType) => {


  const padZero = (num: number, len: number = 2): string => {
    if (len === 2) { return num < 10 ? `0${num}` : `${num}` }
    else { return num < 10 ? `00${num}` : (num < 100 ? `0${num}` : `${num}`) }
  };
  const initNow = new Date();
  const initFrom = new Date(initNow.getFullYear(), initNow.getMonth(), initNow.getDate() - 30);
  const fIsNow = `${initNow.getFullYear()}-${padZero(initNow.getMonth() + 1)}-${padZero(initNow.getDate())}`;
  const fInitNow = `${initNow.getFullYear()}${padZero(initNow.getMonth() + 1)}${padZero(initNow.getDate())}`;
  const fInitFrom = `${initFrom.getFullYear()}${padZero(initFrom.getMonth() + 1)}${padZero(initFrom.getDate())}`;
  const [fromDate, setFromDate] = useState(fInitFrom);
  const [isFromDate, setIsFromDate] = useState(false);
  const [toDate, setToDate] = useState(fInitNow);
  const [isToDate, setIsToDate] = useState(false);
  const [isNow, setIsNow] = useState(fIsNow);
  const navigate = useNavigate()
  const [aiMeasureList, setAiMeasureList] = useState<any>([])
  const [searchKey, setSearchKey] = useState("");
  const [totalCnt, setTotalCnt] = useState(0)
  const [satisCnt, setSatisCnt] = useState(0)
  const [dissatisCnt, setDissatisCnt] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isPopBtm, setIsPopBtm] = useState(false)
  const [isLoading, setIsLoading] = useState(false);


  // const [pagingSize, setPagingSize] = useState(0)
  const [viewCnt, setViewCnt] = useState(12)

  // 데이터 가져오는거 람다 병렬 처리
  const getAiMeasureList = async () => {
    setIsLoading(true)
    const coutry_name_list = countries.map(country => country.label)

    // 각 호출을 Promise로 감싸고 배열에 넣습니다.
    const promises = countries.map(async country => {
      const param = {
        command: "get_measure_cnt",
        index_name: "country-range-index",
        countries: [country.label],
        from_date: fromDate,
        to_date: toDate,
      };

      // 비동기 호출을 await 키워드로 기다립니다.
      const res = await visaApi.common_request("adminMain", param);
      return res;
    });
    // 모든 Promise를 병렬로 실행
    const results = await Promise.all(promises);
    let tempList: any = []
    let satis_cnt = 0
    for (let result of results) {
      let myData = result.response.result[0]
      if (myData.answer_cnt === 0) {
        satis_cnt += 1
        myData.satis_percent = 101
      }
      else if (myData.dislike_cnt === 0) {
        satis_cnt += 1
        myData.satis_percent = 100
      }
      else {
        myData.satis_percent = (myData.answer_cnt - myData.dislike_cnt) / myData.answer_cnt * 100
      }
      tempList.push(myData)
    }

    // 업데이트 날짜 넣기
    let myList2: any = [];
    const visaparam: any = {
      command: "get_visa_proj",
      pk: "entry#visa",
      projection: 'country, range, insert_dt'
    };
    const visa_res = await visaApi.common_request("adminMain", visaparam);
    if (visa_res.code === "200") {
      let visaDataList = visa_res.response.result_list
      for (const myData of tempList) {
        const matchedVisaData = visaDataList.find((visaData: any) => visaData.country === myData.country);
        if (matchedVisaData) {
          const newData: any = { ...myData }
          newData.insert_dt = matchedVisaData.insert_dt;
          newData.range = matchedVisaData.range;
          myList2.push(newData)
        }
      }
    }

    // 토탈 개수
    setTotalCnt(myList2.length)
    setSatisCnt(satis_cnt)
    setDissatisCnt(myList2.length - satis_cnt)
    // 학습률 낮은순으로 정렬
    const sortedList = [...myList2].sort((a, b) => {
      if (a.satis_percent < b.satis_percent) return -1;
      if (a.satis_percent > b.satis_percent) return 1;
      // //학습률 같으면 update날짜로 정렬
      // if (a.range> b.range) return -1;
      // if (a.range < b.range) return 1;
      return 0;
    });
    setAiMeasureList(sortedList)
    setIsLoading(false)
  }

  const handelSearchKey = (search: string) => {
    setSearchKey(search)
    setCurrentPage(1)
  }

  const handleIsPopBtm = () => {
    setIsPopBtm(!isPopBtm)
  }

  const filteredAiMeasureList = aiMeasureList.filter((item: any) => {
    return item.country.toLowerCase().includes(searchKey.toLowerCase())
  });

  // 페이지 변경 핸들러
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };


  const setPeriod = (day: number) => {
    if (day !== 0) {
      const now = new Date();
      const fromTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day);
      const formattedNow = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}`;
      const formattedFrom = `${fromTime.getFullYear()}${padZero(fromTime.getMonth() + 1)}${padZero(fromTime.getDate())}`;
      setToDate(formattedNow);
      setFromDate(formattedFrom);
    }
  }

  const handelSearch = () => {
    if (fromDate > toDate) {
      alert("날짜를 수정해주세요.")
      return;
    }
    setIsPopBtm(false)
    getAiMeasureList()
  }

  const exceldownload = async (country: any) => {

    let myData = []
    const visaparam: any = {
      command: "get_visachatcontent_idx",
      country: country,
      index_name: "country-range-index",
    };
    const res = await visaApi.common_request("adminMain", visaparam);
    if (res.code === "200") {
      myData = res.response.result
    }
    else if (res.code === "300") {
      console.log(" 300 res.response : ", res.response)
    }
    else if (res.code === "500") {
      console.log(" 500 res.response : ", res.response)
    }

    // 엑셀만들기전 전처리 
    const columnsToRemove = ['question', 'read_flag', 'ttl', 'id'];
    const newData = myData.map((item: any) => {
      let newItem = { ...item };
      columnsToRemove.forEach(columnToRemove => {
        delete newItem[columnToRemove];
      });
      return newItem;
    });
    // 엑셀 다운 
    ExcelDownloader(newData, `${country}-${fromDate}~${toDate}.xlsx`)
  }

  useEffect(() => {
    if (isFromDate) {
      let YYYY = parseInt(fromDate.substring(0, 4));
      let MM = parseInt(fromDate.substring(4, 6));
      let DD = parseInt(fromDate.substring(6, 8));
      let YYYYFlag = false;
      let MMFlag = false;

      if (YYYY < 2023) {
        YYYY = 2023;
      }
      else if (YYYY >= parseInt(isNow.substring(0, 4))) {
        YYYY = parseInt(isNow.substring(0, 4));
        YYYYFlag = true;
      }

      if (YYYYFlag) {
        if (MM > parseInt(isNow.substring(5, 7))) {
          MM = parseInt(isNow.substring(5, 7));
          MMFlag = true;
        }
        else if (MM < 1) {
          MM = 1;
        }
      }
      else {
        if (MM > 12) {
          MM = 12;
        }
        else if (MM < 1) {
          MM = 1;
        }
      }

      if (MMFlag) {
        if (DD > parseInt(isNow.substring(8, 10))) {
          DD = parseInt(isNow.substring(8, 10));
        }
        else if (DD < 1) {
          DD = 1;
        }
      }
      else {
        const daysInMonth = new Date(YYYY, MM, 0).getDate();
        if (DD > daysInMonth) {
          DD = daysInMonth;
        }
        else if (DD < 1) {
          DD = 1;
        }
      }

      const sMM = MM.toString().padStart(2, '0');
      const sDD = DD.toString().padStart(2, '0');

      setFromDate(`${YYYY}${sMM}${sDD}`);
      setIsFromDate(false);
    }
  }, [isFromDate])

  useEffect(() => {
    if (isToDate) {
      let YYYY = parseInt(toDate.substring(0, 4));
      let MM = parseInt(toDate.substring(4, 6));
      let DD = parseInt(toDate.substring(6, 8));
      let YYYYFlag = false;
      let MMFlag = false;

      if(YYYY < 2023) {
        YYYY = 2023;
      } 
      else if (YYYY >= parseInt(isNow.substring(0, 4))) {
        YYYY = parseInt(isNow.substring(0, 4));
        YYYYFlag = true;
      }

      if (YYYYFlag) {
        if (MM > parseInt(isNow.substring(5, 7))) {
          MM = parseInt(isNow.substring(5, 7));
          MMFlag = true;
        }
        else if (MM < 1) {
          MM = 1;
        }
      }
      else {
        if (MM > 12) {
          MM = 12;
        }
        else if (MM < 1) {
          MM = 1;
        }
      }

      if (MMFlag) {
        if (DD > parseInt(isNow.substring(8, 10))) {
          DD = parseInt(isNow.substring(8, 10));
        }
        else if (DD < 1) {
          DD = 1;
        }
      }
      else {
        const daysInMonth = new Date(YYYY, MM, 0).getDate();
        if (DD > daysInMonth) {
          DD = daysInMonth;
        }
        else if (DD < 1) {
          DD = 1;
        }
      }

      const fromDateObj = new Date(parseInt(fromDate.substring(0, 4)), parseInt(fromDate.substring(4, 6)) - 1, parseInt(fromDate.substring(6, 8)));
      const toDateObj = new Date(YYYY, MM - 1, DD);
      if (toDateObj < fromDateObj) {
        // If toDate is before fromDate, set it to fromDate
        YYYY = fromDateObj.getFullYear();
        MM = fromDateObj.getMonth() + 1;
        DD = fromDateObj.getDate();
      }
      const sMM = MM.toString().padStart(2, '0');
      const sDD = DD.toString().padStart(2, '0');

      setToDate(`${YYYY}${sMM}${sDD}`);
      setIsToDate(false);
    }
  }, [isToDate])

  useEffect(() => {
    getAiMeasureList();
  }, [])


  const handleFromPeriod = (period: string) => {
    let formatPeriod = period.replace(/-/g, '')
    setFromDate(formatPeriod);
    setIsFromDate(true);
  }
  const handleToPeriod = (period: string) => {
    let formatPeriod = period.replace(/-/g, '')
    setToDate(formatPeriod);
    setIsToDate(true);
  }


  return <>
    <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
      <div id="contents">
        <div className="secCont evaluation">
          {/* 세부정보 읽기 */}
          <div className="contBody">
            <div className="contHeader">
              <div className="tit">
                {/* <a class="btnPrev">이전</a> */}
                <p>AI 관리평가</p>
              </div>
              {/*<div class="rightbtnArea">
								<a onclick="" class="btnEmail">이메일 전송</a>
								<a onclick="" class="btnLineGray">수정</a>
								<a onclick="" class="btnLineGray">삭제</a>
							</div>*/}
            </div>
            <div className="contBox">
              <div className="boardInfo">
                <div className="info">
                  <dl className="total number">
                    <dt className="fontGray">Total</dt>
                    <dd className="fontB">{totalCnt}</dd>
                  </dl>
                  <dl className="total number">
                    <dt className="fontGray">만족</dt>
                    <dd className="fontBlue fontB">{satisCnt}</dd>
                  </dl>
                  <dl className="total number">
                    <dt className="fontGray">학습필요</dt>
                    <dd className="fontRed fontB">{dissatisCnt}</dd>
                  </dl>
                  <div className="period popBottomD">
                    <p className="fontGray">평가기간</p>
                    <div className="fontBlack fontB">
                      {fromDate ?
                        `${fromDate.substring(0, 4)}년 ${fromDate.substring(4, 6)}월 ${fromDate.substring(6, 8)}일~${toDate.substring(0, 4)}년 ${toDate.substring(4, 6)}월 ${toDate.substring(6, 8)}일`
                        :
                        "연도. 월. 일. ~ 연도. 월. 일."}
                      <a className="btnLineSmall trigger" style={{ cursor: "pointer" }} onClick={handleIsPopBtm}>기간설정</a>
                      <a className="btnLineSmall trigger" style={{ cursor: "pointer" }} onClick={handelSearch}>검색</a>
                      {isPopBtm ?
                        <>
                          <div className="popBtm" style={{ display: "block", userSelect: "none" }}>
                            <ul>
                              <li><a style={{ cursor: "pointer" }} className="btnLineGray setPeriod" onClick={() => { setPeriod(30) }}>1개월</a></li>
                              <li><a style={{ cursor: "pointer" }} className="btnLineGray setPeriod" onClick={() => { setPeriod(90) }}>3개월</a></li>
                              <li><a style={{ cursor: "pointer" }} className="btnLineGray setPeriod" onClick={() => { setPeriod(180) }}>6개월</a></li>
                              <li><a style={{ cursor: "pointer" }} className="btnLineGray setPeriod" onClick={() => { setPeriod(0) }}>직접입력</a></li>
                            </ul>
                            <div className="selDate">
                              <input type="date" min="2023-03-01" max={isNow} onChange={(e) => { handleFromPeriod(e.target.value) }} />~
                              <input type="date" min="2023-03-01" max={isNow} className="today" onChange={(e) => { handleToPeriod(e.target.value) }} />
                            </div>
                          </div>
                        </>
                        :
                        <></>
                      }

                    </div>
                  </div>
                  {/* Ai chat - 담당자 문의 팝업 */}
                </div>
                <div className="rightbtnArea">
                  <input
                    className="search"
                    id="search"
                    name="search"
                    placeholder="검색어를 입력해주세요."
                    value={searchKey}
                    onChange={(e) => { handelSearchKey(e.target.value) }} />
                  {/*<a href="" class="btnLineGray new">신규등록</a>*/}
                </div>
              </div>
              {/* 평가 국가 */}
              <div className="managerD">
                {/* 노출갯수 선택 */}
                <div className="perPage">
                  <p className="title">콘텐츠 갯수</p>
                  <ul className="rightBtnArea">
                    {[12, 24, 48, 300].map((cnt) => (
                      <li key={cnt}>
                        <a
                          className={`btnlineSmall ${viewCnt === cnt ? 'on' : ''}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setViewCnt(cnt)}
                        >
                          {cnt === 300 ? '전체보기' : cnt}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* //노출갯수 선택 */}
                {/* all ai */}
                <div className="allAi cardD">
                  {filteredAiMeasureList.slice(((currentPage - 1) * viewCnt), ((currentPage - 1) * viewCnt) + viewCnt).map((aiMeasure: any, index: any) => {
                    const foundCountry = countries.find(country => country.label === aiMeasure.country);
                    if (foundCountry) {
                      return (
                        <div className="aiCard" key={index}>{/* 검색된 국가는 class="aiCard"에 searchai 추가 */}
                          <div className="cardT">
                            <a style={{ cursor: "pointer" }} onClick={() => navigate(`/visalist/${foundCountry.code}`)}>
                              <div className="country">
                                <p className="flag"><img src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`} alt={aiMeasure.country} /></p>
                                <p className="title">{aiMeasure.country} 상담사</p>
                              </div>
                              <div className={`satisfaction ${aiMeasure.dislike_cnt === 0 ? 'blind' : ''}`}>
                                <p className="bad">
                                  <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_bad.svg" />학습필요
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="cardCont">
                            {/* 버튼 비활성인 경우 class="inactive" 추가 */}
                            {aiMeasure.satis_percent === 101 ?
                              <a className="voc inactive" >고객의견</a> :
                              <a className="voc" style={{ cursor: "pointer" }} onClick={() => exceldownload(foundCountry.label)}>고객의견</a>
                            }
                            <div className="successRate">
                              <div className='score'>
                                <span className={`success ${aiMeasure.dislike_cnt === 0 ? 'allgood' : ''}`}>
                                  {aiMeasure.answer_cnt - aiMeasure.dislike_cnt}</span><span className="total">{aiMeasure.answer_cnt}</span>
                              </div>
                              <div className="graph">
                                <div className="graph-wrap active-on-visible" data-active-on-visible-callback-func-name="CircleRun">
                                  <div className="circle-graph">
                                    {aiMeasure.satis_percent === 101 ?
                                      <CircleGraph percent={0} duration={100}></CircleGraph>
                                      :
                                      <CircleGraph percent={aiMeasure.satis_percent.toFixed(1)} duration={100}></CircleGraph>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cardB">
                            <dl className="dateD">
                              <dt>업데이트</dt>
                              <dd>{aiMeasure.range.substring(0, 4)}-{aiMeasure.range.substring(4, 6)}-{aiMeasure.range.substring(6, 8)}</dd>
                              {/* <dd>2024-03-05</dd> */}
                            </dl>
                            <dl className="dateD">
                              <dt>생성일</dt>
                              <dd>{aiMeasure.insert_dt.substring(0, 4)}-{aiMeasure.insert_dt.substring(4, 6)}-{aiMeasure.insert_dt.substring(6, 8)}</dd>
                              {/* <dd>2024-02-02</dd> */}
                            </dl>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
                {/* //all ai */}
                <div className={viewCnt === 300 ? "blind" : "pagination"}>
                  <Pagination
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    size="large"
                    className="page"
                    count={Math.ceil(filteredAiMeasureList.length / viewCnt)} // 전체 페이지 수 계산
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ '&:hover': { cursor: 'pointer' } }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* // 세부정보 읽기 */}
        </div>
      </div>{/*// contents */}
    </div > {/*// container */}
    <LoadingCircle loading={isLoading} />

  </>
};

export default AiMeasurePage;
