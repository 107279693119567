import { Alert, Avatar, Box, Button, Stack } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import dayjs, { Dayjs } from "dayjs";

import { userState } from "../../interface/MainInterface";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { countries } from "../components/CountryList";
import LoadingCircle from "../../utils/LoadingCircle";


interface propsType {
  userState: userState;
}
interface SafetyInfoType {
  idx: number;
  country: string;
  range: string;
  title: string;
  content: string;
  attach_files: any;
}

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);
const PAGING_SIZE = 5                               // 화면에 보여줄 페이징 수
const NOTICE_PER_PAGE = 9                          // 한 화면에 보여줄 글 개수
const NOTICE_CNT = PAGING_SIZE * NOTICE_PER_PAGE      // 한번 ddb에서 가져올 글 개수

const SafetyInfoPage = (props: propsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevState = location.state?.prevState || {};
  const [safetyList, setSafetyList] = useState<SafetyInfoType[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRange, setCurrentRange] = useState(1);
  const [pageState, setPageState] = useState(prevState);
  const [isInitialSet, setIsInitialSet] = useState(false);

  const changeSearchKey = (e: any) => {
    setSearchKey(e);
    setCurrentPage(1);
  }

  const filteredSafetyList = safetyList.filter((item) => {
    // You can customize the search logic here based on your requirements
    return item.title.toLowerCase().includes(searchKey.toLowerCase()) ||
      item.country.toLowerCase().includes(searchKey.toLowerCase());
  });

  // 페이지 변경 핸들러
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const setSafetyInfoList = async () => {
    let _maxCount: number = 3;
    let idx: number = 1;

    const param: any = {
      command: "get_information",
      pk: "safety#visa",
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const res = await visaApi.common_request("adminMain", param);

    if (res.code === "200") {
      const searchResult = res.response.result_list;
      for (const source of searchResult) {
        const match = source['sk'].match(/^(\d{4})(\d{2})(\d{2})/);
        if (match) {
          source['range'] = `${match[1]}-${match[2]}-${match[3]}`;
        }
        source['idx'] = idx;
        idx = idx + 1;
      }

      const reversed = searchResult.reverse();
      setSafetyList(reversed);
    }
    setIsInitialSet(true);
  };

  useEffect(() => {
    setCurrentPage((currentRange - 1) * PAGING_SIZE + 1)
  }, [currentRange])

  useEffect(() => {
    setSafetyInfoList();
    const storedData = window.localStorage.getItem(appConfig.safetySearchKey01);
    const storedPage = window.localStorage.getItem(appConfig.safetyPageKey01);
    if (storedData !== null && storedData !== "") {
      try {
        const recentSearchData = JSON.parse(storedData);
        setSearchKey(recentSearchData);
        window.localStorage.setItem(
          appConfig.safetySearchKey01,
          JSON.stringify('')
        );
      } catch (error) {
        console.error("Parsing SearchKey error:", error);
      }
    }
    if (storedPage !== null && storedPage !== "") {
      try {
        const recentPageData = JSON.parse(storedPage);
        setCurrentPage(recentPageData);
        window.localStorage.setItem(
          appConfig.safetyPageKey01,
          JSON.stringify(1)
        );
      } catch (error) {
        console.error("Parsing PageKey error:", error);
      }
    }
    return () => {
      setSearchKey('');
      setCurrentPage(1);
    };
  }, []);

  return (
    <>
      <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
        <div id="contents">
          <div className="secCont board">
            {/* 세부정보 읽기 */}
            <>
              <div className="contBody">
                <div className="contHeader">
                  <div className="tit">
                    해외 안전 정보
                  </div>
                </div>
                <div className="contBox">
                  <div className="boardInfo">
                    <div className="info">
                      <dl className="total number">
                        <dt className="fontGray">Total</dt>
                        <dd className="fontBlue fontB">{safetyList.length}</dd>
                      </dl>
                    </div>
                    <Box className="rightbtnArea" >
                      <Box sx={{ position: "relative", right: "1px" }}>
                        <Stack direction={"row"} spacing={2} sx={{ alignItems: "center", ".MuiStack-root": { overflow: "unset" } }}>
                          <input
                            value={searchKey}
                            className="search"
                            id="search"
                            name="search"
                            placeholder="검색어를 입력해주세요."
                            style={{ height: "40px" }}
                            onChange={(e) => { changeSearchKey(e.target.value) }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </div>
                  <table className="boardD">
                    <colgroup>
                      <col className="num" />
                      <col className="country" />
                      <col className="title" />
                      <col className="writer" />
                      <col className="date" />
                    </colgroup>
                    <thead>
                      <tr>
                        <td scope="col">번호</td>
                        <td scope="col">국가</td>
                        <td scope="col">제목</td>
                        <td scope="col">담당자</td>
                        <td scope="col">등록일</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSafetyList.slice(((currentPage - 1) * NOTICE_PER_PAGE), ((currentPage - 1) * NOTICE_PER_PAGE) + NOTICE_PER_PAGE).map((item: any, index) => {
                        const foundCountry = countries.find(country => country.label === item.country);
                        if (foundCountry) {
                          return (
                            <tr
                              onClick={() => {
                                window.localStorage.setItem(
                                  appConfig.safetySearchKey01,
                                  JSON.stringify(searchKey)
                                );
                                window.localStorage.setItem(
                                  appConfig.safetyPageKey01,
                                  JSON.stringify(currentPage)
                                );
                                navigate(`/safetyNewsListRead/${item.sk}`, { state: { selectedItem: item } })
                              }}
                              key={index}
                            >
                              <td>{item.idx}</td>
                              <td className="bCountry">
                                <span><img src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`} /></span>
                                <span>{item.country}</span>
                              </td>
                              <td className="boardtit">
                                <a >{item.title}</a>
                              </td>
                              <td>-</td>
                              <td>{item.range}</td>
                            </tr>
                          )
                        }
                        // 임시코드 - 국가코드 없는 나라
                        else {
                          return (
                            <tr
                              onClick={() => {
                                window.localStorage.setItem(
                                  appConfig.safetySearchKey01,
                                  JSON.stringify(searchKey)
                                );
                                window.localStorage.setItem(
                                  appConfig.safetyPageKey01,
                                  JSON.stringify(currentPage)
                                );
                                navigate(`/safetyNewsListRead/${item.sk}`, { state: { selectedItem: item } })
                              }}
                              key={index}
                            >
                              <td>{item.idx}</td>
                              <td className="bCountry" style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar style={{ width: 24, height: 24, marginRight: 3 }}>
                                  <span style={{ fontSize: 16, alignItems: 'center' }}>{item.country.charAt(0)}</span>
                                </Avatar>
                                <span>{item.country}</span>
                              </td>
                              <td className="boardtit">
                                <a >{item.title}</a>
                              </td>
                              <td>-</td>
                              <td>{item.range}</td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      size="large"
                      className="page"
                      count={Math.ceil(filteredSafetyList.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                      page={currentPage}
                      onChange={handlePageChange}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    />
                  </div>
                </div>
              </div>
            </>
            {/* // 세부정보 읽기 */}
          </div>
        </div>{/*// contents */}
      </div>{/*// container */}
      <LoadingCircle loading={!isInitialSet} />
    </>
  )
};

export default SafetyInfoPage;
