export const appConfig = {
  REACT_APP_VISA_API_DEV:
    "https://apidev.ai-hyundaidreamtour.com",
  REACT_APP_VISA_API_PROD:
    "https://api.ai-hyundaidreamtour.com",
  REACT_APP_VECTOR_CORE:
    "https://58wq5zhhc1.execute-api.ap-northeast-2.amazonaws.com/Dev",
  REACT_APP_TENANT_KEY: "5b6f70de-f45c-4598-bf9b-b9695c01e285",
  sessionName: "gptdreamtourss", // 사이트마다 달라야 함
  rememberidSSName: "gdtrmbrid", // 사이트마다 달라야 함
  seed: "8894114526",
  prodURL: "www.ai-hyundaidreamtour.com",
  // prodURL: "localhost",
  AES_KEY: "f68e9644c50585cded94773dffabdc98347b0cbfc557593b567cdc337cfd854c",
  AES_IV: "608da861ef8df56c0a27eb0b7958c14d",
  recentSearchKey01: "dreamMainrecentSearchKey01",
  safetySearchKey01: "dreamMainsafetySearchKey01",
  safetyPageKey01: "dreamMainsafetyPageKey01",
  noticeSearchKey01: "dreamMainnoticeSearchKey01",
  myaskSearchKey01: "dreamMainMyaskSearchKey01",
  myaskPageKey01: "dreamMainMyaskPageKey01",
  aidataSearchKey01: "dreamMainAidataSearchKey01",
  aidataPageKey01: "dreamMainAidataPageKey01",
};
