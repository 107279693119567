import {
  useEffect,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box, List, Typography, ListItem, ListItemButton, Avatar } from "@mui/material";

import { userState, DreamMenuItem } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";

import { HttpCustomApi } from "../interface/custom-api";
import { appConfig } from "../config/Config";

interface propsType {
  userState: userState;
  selectedMenu: string;
  setLeftUpdate: any;
  leftUpdate: boolean;
}
interface LatestVisaType {
  sk: string;
  content: string;
  country: string;
  day: string;
  new_content: string;
  time: string;
  title: string;
  url: string;
  status: string;
}

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

// 공통 메뉴 : 일반사용자 접근
export const MENU: DreamMenuItem[] = [
  {
    name: "AI 채팅",
    className: "mm chat",
    classNameSelect: "mm on chat",
    path: "/visaaichat",
  },
  {
    name: "중요 공지 사항",
    className: "mm notice",
    classNameSelect: "mm on notice",
    path: "/notice",
  },
  {
    name: "해외 안전 정보",
    className: "mm safetynews",
    classNameSelect: "mm on safetynews",
    path: "/safetyinfo",
  },
  {
    name: "FAQ",
    className: "mm faq",
    classNameSelect: "mm on faq",
    path: "/faq",
  },
  {
    name: "나의 문의내역",
    className: "mm myqlist",
    classNameSelect: "mm on myqlist",
    path: "/myask",
  },
];
// 공통 메뉴 : 관리자 접근
export const ADMIN_MENU: DreamMenuItem[] = [
  {
    name: "AI 채팅",
    className: "mm chat",
    classNameSelect: "mm on chat",
    path: "/visaaichat",
  },
  {
    name: "국가별 비자/입국 상세정보",
    className: "mm visa",
    classNameSelect: "mm on visa",
    path: "/visalist",
  },
  {
    name: "중요 공지 사항",
    className: "mm notice",
    classNameSelect: "mm on notice",
    path: "/notice",
  },
  {
    name: "해외 안전 정보",
    className: "mm safetynews",
    classNameSelect: "mm on safetynews",
    path: "/safetyinfo",
  },
  {
    name: "데이터 관리",
    className: "mm data",
    classNameSelect: "mm on data",
    path: "/aidata",
  },
  {
    name: "FAQ",
    className: "mm faq",
    classNameSelect: "mm on faq",
    path: "/faq",
  },
  {
    name: "나의 문의내역",
    className: "mm myqlist",
    classNameSelect: "mm on myqlist",
    path: "/myask",
  },
  {
    name: "AI 평가관리",
    className: "mm evaluation",
    classNameSelect: "mm on evaluation",
    path: "/aimeasure",
  },
];
const LeftMenu = (props: propsType, ref: any) => {
  let navigate = useNavigate();
  const [listLength, setListLength] = useState(0);
  const [expandMenu, setExpandMenu] = useState<any>([]);
  const [visaConfirmList, setVisaConfirmList] = useState<LatestVisaType[]>([]);

  const signOut = () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    const result = AuthMain.logOut();
    if (result) window.location.href = "/";
  };

  const latestVisaList = async () => {
    let length = 0;
    const param: any = {
      command: "get_information_proj",
      pk: "rpa#visa",
      projection: 'sk, day, status'
    };

    const res = await visaApi.common_request("adminMain", param);

    if (res.code === "200") {
      let myList: any = [];
      let useList: any = [];

      for (const myask of res.response.result_list) {
        myList.push(myask);
      }

      for (let aidata of myList) {
        if (aidata.status !== "300") {
          useList.push(aidata);
          if (aidata.status !== "200" && aidata.status !== "201") {
            length++;
          }
        }
      }

      setListLength(length);
      // 정렬
      useList.sort((a: any, b: any) => {
        if (a.day > b.day) return -1;
        if (a.day < b.day) return 1;
        return 0;
      });

      setVisaConfirmList(useList);
    }
    props.setLeftUpdate(false);
  };

  useEffect(() => {
    latestVisaList();
    return () => {
      setExpandMenu([]);
    };
  }, []);

  useEffect(() => {
    // 메뉴 변경시 하일라이트를 위한 State 계산
    for (const menu of MENU) {
      if (menu.children) {
        for (const child of menu.children) {
          if (props.selectedMenu.split("/")[1] === child.path.split("/")[1]) {
            let temp = { ...expandMenu };
            temp[menu.path] = true;
            setExpandMenu(temp);
          }
        }
      }
    }
  }, [props.selectedMenu, expandMenu]);

  useEffect(() => {
    if (props.leftUpdate) {
      latestVisaList();
    }
  }, [props.leftUpdate]);

  const UserMenu = () => {
    return (
      <List
        className="nav"
        sx={{
          padding: "0 0 0 0px",
        }}
      >
        {MENU.map((menuInfo, index) =>
          menuInfo.adminOnly && !props.userState.isAdmin ? (
            <Box key={index}></Box>
          ) : (
            <ListItem
              key={index}
              sx={{
                padding: "0 0 0 0px",
              }}
            >
              <ListItemButton
                className={
                  menuInfo.path === props.selectedMenu
                    ? menuInfo.classNameSelect
                    : menuInfo.className
                }
                selected={menuInfo.path === props.selectedMenu}
                sx={{
                  fontWeight: 500,
                  color: "#000",
                  lineHeight: "20px",
                  padding: "0 0 0 0px",
                  position: "relative",
                  bgcolor: "transparent !important",
                  WebkitFlexGrow: 0,
                  flexGrow: 0,
                }}
                component="a"
                onClick={() => {
                  move(menuInfo.path);
                }}
              >
                {menuInfo.name}
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    );
  };

  const AdminMenu = () => {
    return (
      <List
        className="nav"
        sx={{
          padding: "0 0 0 0px",
        }}
      >
        {ADMIN_MENU.map((menuInfo, index) =>
          menuInfo.adminOnly && !props.userState.isAdmin ? (
            <Box key={index}></Box>
          ) : (
            <ListItem
              key={index}
              sx={{
                padding: "0 0 0 0px",
              }}
            >
              <ListItemButton
                className={
                  menuInfo.path === props.selectedMenu
                    ? menuInfo.classNameSelect
                    : menuInfo.className
                }
                selected={menuInfo.path === props.selectedMenu}
                sx={{
                  fontWeight: 500,
                  color: "#000",
                  lineHeight: "20px",
                  padding: "0 0 0 0px",
                  position: "relative",
                  bgcolor: "transparent !important",
                  WebkitFlexGrow: 0,
                  flexGrow: 0,
                }}
                component="a"
                onClick={() => {
                  move(menuInfo.path);
                }}
              >
                {menuInfo.name}
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    );
  };

  const LeftLogo = () => {
    return (
      <>
        <Typography
          variant="h1"
          className="logo"
          style={{ cursor: "pointer" }}
          onClick={handleLogoClick}
        >
          <img
            src="https://img.hyundaidreamtour.com/images/aihdt/visaai_bi.png"
            alt="현대드림투어 AI"
            style={{ display: "flex", alignItems: "center", height: "auto" }}
          />
        </Typography>
      </>
    );
  };

  const LeftUpdateIng = () => {
    return (
      <>
        <Box className="upCont">
          <Box className="upDate">
            <p className="gnbTit">Update 날짜</p>
            {visaConfirmList.length > 0 && visaConfirmList[0] && (
              <p className="output">{visaConfirmList[0].day}</p>
            )}
          </Box>
          <Box className="upProg">
            <p className="gnbTit">Update 진행율</p>
            <p className="output">{100 - listLength}%</p>
          </Box>
        </Box>
      </>
    );
  };

  const LeftHelper = () => {
    return (
      <>
        <div className="coverBar"></div>
      </>
    );
  };

  const LeftLogout = () => {
    return (
      <>
        <Box className="personalCont">
          <Box className="personal">
            <Avatar
              src={props.userState.imageUrl}
              alt={props.userState.name}
              sx={{ width: 32, height: 32 }}
            />
            {/* 팀즈 증명사진 연동 가능한지 확인부탁드려요 */}
            <span className="name">{props.userState.name}</span>
          </Box>
          <Box className="rightBtnArea">
            {/*<a href="" class="btnLine mypage">Mypage</a> 2024-02-02 숨김처리 */}
            <a className="btnLine logout"
              onClick={signOut}
              style={{ cursor: "pointer" }}
            >
              Logout
            </a>
          </Box>
        </Box>
      </>
    );
  };

  const getLeftCount = () => {
    // 기본 조회 넣기
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getLeftCount,
  }));

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  // 메뉴 클릭시 페이지 이동
  const move = useCallback(
    (loc: string) => {
      navigate(loc);
    },
    [navigate]
  );
  return (
    <>
      <Box id="header">
        <Box className="gnb scroll">
          <LeftLogo />
          {props.userState.isAdmin ? <AdminMenu /> : <UserMenu />}
          <LeftUpdateIng />
          <LeftHelper />
          <LeftLogout />
        </Box>
      </Box>
    </>
  );
};

export default forwardRef(LeftMenu);
