import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

interface propsType {
  loading: boolean;
}

const LoadingCircle = (props: propsType) => {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }} open={props.loading}>
        <Stack direction="column">
          <CircularProgress color="inherit" />
        </Stack>
      </Backdrop>
    </>
  );
};

export default LoadingCircle;
