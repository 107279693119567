import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";

import TextField from "@mui/material/TextField";
import { userState } from "../../interface/MainInterface";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  userState: userState;
}

export default function MyAskDetail(props: propsType) {
  const navigate = useNavigate();
  const location = useLocation();
  // const { sk } = useParams();
  const myAskProp = location.state.selectedItem;
  const totalCnt = location.state.tCnt;
  const completCnt = location.state.cCnt;
  const watingCnt = location.state.wCnt;
  const [answerText, setAnswerText] = useState("");
  const [myAsk, setMyAsk] = useState<any>();

  const getMyAsk = async () => {
    const param: any = {
      command: "get_information_sk",
      pk: "myask#visa",
      sk: myAskProp.sk,
    };

    const res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      setMyAsk(res.response.result_list[0]);
    } else {
      alert("잠시뒤에 다시 시도해주세요.");
      return;
    }
  };

  const padZero = (num: number, len: number = 2): string => {
    if (len === 2) {
      return num < 10 ? `0${num}` : `${num}`;
    } else {
      return num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`;
    }
  };

  const handleUpload = async () => {
    if (answerText === "") {
      alert("답변을 작성해주세요.");
      return;
    }

    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;

    //덮어쓰기
    let param: any = {
      command: "put_information",
      pk: "myask#visa",
      sk: myAsk?.sk,
      title: myAsk?.title,
      content: myAsk?.content,
      category: myAsk?.category,
      answer: answerText,
      editor: myAsk?.editor,
      worker: props.userState.name,
      answer_state: "Y",
      update_dt: formattedTime,
    };
    // 문의하기 전용 GSI
    param.myask_visa_pk = myAsk?.myask_visa_pk;
    param.myask_visa_sk = myAsk?.myask_visa_sk;

    const myaskset = await visaApi.common_request("adminMain", param);
    if (myaskset.code === "200") {
      alert("등록 완료");
      navigate("/myask");
    } else {
      alert("등록에 실패하였습니다. 다시 한번 시도해주세요.");
      return;
    }
  };

  useEffect(() => {
    getMyAsk();
  }, []);

  return (
    <>
      <div id="container">
        {" "}
        {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
        <div id="contents">
          <div className="secCont board">
            {/* 세부정보 읽기 */}
            <div className="contBody">
              <div className="contHeader">
                <div className="tit">
                  나의 문의내역
                  {/*<a class="btnPrev">이전</a>
								<p class="nation">
									<img src="https://img.hyundaidreamtour.com/images/aihdt/flag/flag_in.png" alt="인도" >
									<span>인도</span>							
								</p>
 								<p class="date">2024-01-15 15:50</p>*/}
                </div>
                {/*<div class="rightbtnArea">
								<a onclick="" class="btnEmail">이메일 전송</a>
								<a onclick="" class="btnLineGray">수정</a>
								<a onclick="" class="btnLineGray">삭제</a>
							</div>*/}
              </div>
              <div className="contBox">
                <div className="boardInfo">
                  <div className="info">
                    <dl className="total number">
                      <dt className="fontGray">Total</dt>
                      <dd className="fontBlack fontB">{totalCnt}</dd>
                    </dl>
                    <dl className="total number">
                      <dt className="fontGray">답변완료</dt>
                      <dd className="fontBlue fontB">{completCnt}</dd>
                    </dl>
                    <dl className="total number">
                      <dt className="fontGray">답변대기</dt>
                      <dd className="fontRed fontB">{watingCnt}</dd>
                    </dl>
                  </div>
                  <div className="rightbtnArea">
                    <select id="mad_select" name="mad_select">
                      <option>전체</option>
                      <option>제목</option>
                      <option>문의자</option>
                    </select>
                    <input className="search" id="mad_search" name="mad_search" placeholder="검색어를 입력해주세요." />
                    {/*<a href="" class="btnNew btnLineGray">신규등록</a>*/}
                  </div>
                </div>
                <div className="readD">
                  <table className="readTop">
                    <colgroup>
                      <col className="readth" />
                      <col className="readtd1" />
                      <col className="readth" />
                      <col className="readtd2" />
                      <col className="readth" />
                      <col className="readtd3" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>제목</th>
                        <td colSpan={5}>{myAsk?.title}</td>
                      </tr>
                      <tr>
                        <th>문의 유형</th>
                        <td>{myAsk?.category}</td>
                        <th>문의자</th>
                        <td>{myAsk?.editor}</td>
                        <th>등록일</th>
                        <td>
                          {myAsk?.sk.substring(0, 4)}-{myAsk?.sk.substring(4, 6)}-{myAsk?.sk.substring(6, 8)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="readC">{myAsk?.content}</div>
                </div>
                <div className="replyD">
                  <div className="rwriD">
                    <p className="title">답변</p>
                    <div className="textbox" style={{ width: "100%" }}>
                      {/* User 또는 관리자가 답변을 작성한 경우/ 답변대기중인 경우 class="blind" 추가 */}

                      {myAsk?.answer_state === "Y" || props.userState.isAdmin === false ? (
                        <>
                          <p className={`user ${myAsk?.answer_state === "Y" ? "" : "blind"}`} style={{ whiteSpace: "pre-wrap" }}>
                            {myAsk?.answer}
                          </p>
                        </>
                      ) : (
                        <>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={5}
                            value={answerText}
                            onChange={(e) => setAnswerText(e.target.value)}
                            sx={{
                              "& .MuiInputBase-input": {
                                border: "none",
                                background: "transparent",
                              },
                              width: "100%",
                              paddingLeft: "10px",
                              paddingTop: "8px",
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="centerbtnArea">
                  <a className="btnGray" onClick={(e) => navigate(-1)} style={{ cursor: "pointer" }}>
                    목록
                  </a>
                  <a
                    className={`btnGray ${myAsk?.answer_state === "Y" || props.userState.isAdmin === false ? "blind" : ""}`}
                    onClick={handleUpload}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    등록하기
                  </a>
                </div>
              </div>
            </div>
            {/* // 세부정보 읽기 */}
          </div>
        </div>
        {/*// contents */}
      </div>{" "}
      {/*// container */}
    </>
  );
}
