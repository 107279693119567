import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { iNotice } from "../../interface/VisaInterface";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  itemNum: number;
}

const NoticeComponent = (props: propsType) => {

  const navigate = useNavigate();
  const [noticeList, setNoticeList] = useState<iNotice[]>([])
  const [newDate, setNewDate] = useState("");
  const [itemNum, setItemNum] = useState(props.itemNum);
  const getNoticeList = async () => {

    // TODO : 현재는 모두가져옴.
    let idx: number = 1;
    const noticelistparam: any = {
      command: "get_information",
      pk: "notice#visa",
      // from_date: dayjs(fromDate).format("YYYYMMDD"),
      // to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      // sortForward: false,
    };

    const res = await visaApi.common_request("adminMain", noticelistparam);
    if (res.code === "200") {
      const searchResult = res.response.result_list;
      const sortedList = [...searchResult].sort((a, b) => {
        if (a.update_dt < b.update_dt) return 1;
        if (a.update_dt > b.update_dt) return -1;
        return 0;
      });
      setNoticeList(sortedList);
      setNewDate(sortedList[0]['update_dt'].substring(0, 8));
    }


  }

  useEffect(() => {
    getNoticeList();
  }, []);

  return (
    <div className="noticeD">
      <div className="tit">
        <h2>중요 공지 사항</h2>
        <div className="rightBtnArea">
          <a className="btnMore"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/notice`)
            }}>
            더보기
          </a>
        </div>
      </div>
      <ul className="noticeList">
        {noticeList.filter(item => item.hidden_yn === "Y").slice(0, itemNum).map((item: any, index) => (
          <li
            key={index}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/noticelistread/${item.sk}`, { state: { selectedItem: item } })
            }}
          >
            <span className={`label blue ${newDate === item.update_dt.substring(0, 8) ? '' : 'blind'}`}>신규</span>
            <a>{item.country} {item.title}</a>
            <span className="date">{item.update_dt.substring(0, 4)}-{item.update_dt.substring(4, 6)}-{item.update_dt.substring(6, 8)}</span>
          </li>
        ))}
      </ul>
    </div >
  );
};

export default NoticeComponent;
