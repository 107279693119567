import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AttachFileViewer from "../../utils/attach/AttachFileViewer";
import { appConfig } from "../../config/Config";

export default function SafetyDetailRead() {
    const navigate = useNavigate();
    const location = useLocation();
    const { sk } = useParams();
    const attachFileViewerRef: any = useRef();
    const selectedItem = location.state.selectedItem;

    const handleGoBack = () => {
        navigate(-1);
    };

    const handlePrint = () => {
        window.print(); // 브라우저 인쇄 다이얼로그 열기
    };

    useEffect(() => {
        // console.log('Previous state:', selectedItem);
    }, []);

    return (
        <>
            <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
                <div id="contents">
                    <div className="secCont board">
                    {/* 세부정보 읽기 */}
                        <div className="contBody">
                            <div className="contHeader">
                                <div className="tit">
                                    <a className="btnPrev" onClick={handleGoBack} style={{ cursor: 'pointer',}} >이전</a>
                                    <p>해외 안전 정보</p>
                                </div>
                            </div>
                            <div className="contBox">
                                <div className="boardInfo">
                                    <div className="info">
                                    </div>
                                    <div className="rightbtnArea">
                                        <a className="btnLineGray print" onClick={handlePrint} style={{ cursor: 'pointer',}} >인쇄</a>
                                    </div>
                                </div>
                                <div className="readD">
                                    <div className="rtitD">
                                        <div className="title">{selectedItem.title} </div>
                                        <div className="date fontL fontLightGray">{selectedItem.range}</div>
                                    </div>
                                    <div className="rcontD"
                                        dangerouslySetInnerHTML={{ __html: selectedItem.content }} 
                                    />
                                    {(window.location.hostname === appConfig.prodURL) ? (
                                        <AttachFileViewer
                                            ref={attachFileViewerRef}
                                            rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_PROD}
                                            files={selectedItem.attach_files}
                                            canDelete={false}
                                            isBox={false}
                                            callback=''
                                        />
                                    ) : (
                                        <AttachFileViewer
                                            ref={attachFileViewerRef}
                                            rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_DEV}
                                            files={selectedItem.attach_files}
                                            canDelete={false}
                                            isBox={false}
                                            callback=''
                                        />
                                    )}
                                </div>
                            </div>						
                        </div>
                    {/* // 세부정보 읽기 */}
                    </div>
                </div>{/*// contents */}		
            </div>{/*// container */}
        </>
    )
}
