import { ApiBase } from "./api-base";

export class HttpMainApi extends ApiBase {
  onApiName(): string {
    return "MainApi";
  }

  onUrlPath(): string {
    return "/api";
  }

  public async post(param: any) {
    const response = await this.requestPost(param);
    return response;
  }
}
