import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { appConfig } from "../../config/Config";
import CountrySearchMain from "./CountrySearchMain";
import CountryRecentQnaFive from "./CountryRecentQnaFive";

const CountrySearchComponent = () => {
  let navigate = useNavigate();
  const [location, setLocation] = useState<string | null>("");
  const [location_code, setLocationCode] = useState<string | null>("");
  const [recentSearch, setRecentSearch] = useState<any>([]);

  useEffect(() => {
    const storedData = window.localStorage.getItem(appConfig.recentSearchKey01); //로컬 스토리지
    if (storedData !== null && storedData !== "") {
      try {
        const recentSearchData = JSON.parse(storedData);
        setRecentSearch(recentSearchData);
      } catch (error) {
        console.error("Parsing error:", error);
      }
    }
    return () => {
      setRecentSearch([]);
    };
  }, []);

  function fnSearch(location: string | null, location_code: string | null) {
    if (location === null || location.trim() === "" || location_code === null || location_code.trim() === "" ) {
      alert("국가명을 제대로 넣어주세요.");
      return;
    }

    const isDuplicate = recentSearch.some(
      (item: any) => item.location === location
    );

    if (!isDuplicate) {
      let newSearch = { location, location_code };
      let newRecents = [newSearch, ...recentSearch].slice(0, 5);

      setRecentSearch(newRecents);
      window.localStorage.setItem(
        appConfig.recentSearchKey01,
        JSON.stringify(newRecents)
      );
    }

    //페이지 이동
    navigate("/visaaichat", { state: { location, location_code } });
  }

  const handleInputLocation = (location: string, location_code: string) => {
    setLocation(location);
    setLocationCode(location_code);
  };
  const handleDelete = (index: number) => {
    let newRecents: any = [...recentSearch];
    newRecents.splice(index, 1);
    setRecentSearch(newRecents);
    window.localStorage.setItem(appConfig.recentSearchKey01, newRecents);
  };

  return (
    <Box className="dashboard aiDash">
      <Box className="tit">
        <Typography variant="h2">국가별 AI 상담 시작하기</Typography>
      </Box>
      <Box className="searchD">
        <CountrySearchMain
          fnSearch={fnSearch}
          location={location}
          setLocation={setLocation}
          location_code={location_code}
          setLocationCode={setLocationCode}
        />
        <Box className="recent">
          <p className="tit">최근 검색</p>
          <ul className="srchWord">
            {recentSearch
              .slice(0, 5)
              .map(
                (
                  item: { location: string; location_code: string },
                  index: number
                ) => (
                  <li key={index}>
                    <a
                      href="#" // href 속성 추가
                      className="btnRecent"
                      onClick={(e) => {
                        e.preventDefault(); // 기본 동작 방지
                        handleInputLocation(item.location, item.location_code);
                      }}
                    >
                      {item.location}
                    </a>
                    <button
                      className="btnDelete"
                      onClick={(e) => {
                        e.preventDefault(); // 버튼 클릭 시 기본 폼 제출 동작 방지 (필요한 경우)
                        handleDelete(index);
                      }}
                    >
                      삭제
                    </button>
                  </li>
                )
              )}
          </ul>
        </Box>
      </Box>
      <CountryRecentQnaFive />
    </Box>
  );
};

export default CountrySearchComponent;
