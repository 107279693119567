import { userState, FaqProps } from "../../interface/MainInterface";
import { useEffect, useState } from "react";
import { HttpCustomApi } from "../../interface/custom-api";
import { appConfig } from "../../config/Config";

import LoadingCircle from "../../utils/LoadingCircle";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);
interface propsType {
  userState: userState;
}

interface iFaq {
  pk?: any;
  sk?: any;
  title?: any;
  answer?: any;
}

const FaqPage = (props: propsType) => {

  const [faqList, setFaqList] = useState<iFaq[]>([])
  const [totalText, setTotalText] = useState('');
  const [total, setTotal] = useState(0)
  const [accStates, setAccStates] = useState(Array(total).fill(false)); // faq에서 총 개수 가져오기
  const [searchKey, setSearchKey] = useState("");
  const [isInitialSet, setIsInitialSet] = useState(false);

  const toggleAccordion = (index: number) => {
    const newAccStates = [...accStates];
    newAccStates[index] = !newAccStates[index];
    setAccStates(newAccStates);
  };

  const getFaqList = async () => {

    const param: any = {
      command: "get_information",
      pk: "faq#visa",
    };

    const res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      // FAQ 5개만 가져오기
      let temp_list = []
      for (let index = 0; index < 5; index++) {
        temp_list.push(res.response.result_list[index])
      }
      setFaqList(temp_list);
      setTotal(temp_list.length)
    }
    else if (res.code === "300") {
      console.log(" 300 res.response : ", res.response)
    }
    else if (res.code === "500") {
      console.log(" 500 res.response : ", res.response)
    }

    setTotalText(`자주 묻는 질문`);
    setIsInitialSet(true);
  }


  const search_faq_vectordb = async () => {
    // console.log('Send message:', message);
    setIsInitialSet(false);
    const xsearchparam: any = {
        command: "extream_search",      // xsearch
        index_name: "naverx-dreamtour-faq", // 벡터DB의 index명
        question: searchKey, // 사용자 질문
        max_count: 5, // 검색결과 최대 수량
    };

    const xseres = await vectoCoreApi.auth_request(
        "api",
        xsearchparam,
        process.env.REACT_APP_TENANT_KEY
    );

    let tempRows = [];
    if (xseres.code === "200") {
      // console.log("xseres : ", xseres);
      for (let i of xseres['response']['result']) {
        let tempData: FaqProps;
        tempData = {
          'pk': ""
          , 'sk': ""
          , 'title': i['질문']
          , 'answer': i['답변']
        };
        tempRows.push(tempData);
      }
    }
    else {
      let tempData: FaqProps;
      tempData = {
        'pk': ""
        , 'sk': ""
        , 'title': "질문 답변 실패"
        , 'answer': "문제가 계속될 경우 관리자에게 문의 부탁드립니다."
      };
      tempRows.push(tempData);
    }
    setTotalText(`문의하신 질문의 답변`);
    setFaqList(tempRows);
    setIsInitialSet(true);
  };

  useEffect(() => {
    getFaqList()
  }, [])

  return <>
    <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
      <div id="contents">
        <div className="secCont faq">
          {/* 세부정보 읽기 */}
          <div className="contBody">
            <div className="contHeader">
              <div className="tit">
                FAQ
              </div>
            </div>
            <div className="contBox">
              <div className="faqD" style={{ gridGap: 20 }}>
                <div className="faqAi">
                  <div >
                    <p className="title">자주 묻는 질문</p>
                    <p className="fontGray">질문에 대하여 인공지능이 답변을 찾아드립니다.</p>
                  </div>
                  {/* 채팅창 메시지 입력 */}
                  <div className="msgSendWrap" style={{width: "100%"}}>
                    <div className="msgInput">
                      <input className="msgInputText"
                        placeholder="Message 현대드림투어 AI"
                        id="signInPwd"
                        value={searchKey}
                        required
                        autoComplete="off"
                        disabled={!isInitialSet}
                        onChange={(e) => { setSearchKey(e.target.value); }}
                        onKeyDown={(e) => { 
                          if (e.key == 'Enter' && !e.nativeEvent.isComposing) {
                            e.preventDefault();
                            search_faq_vectordb();
                          }
                        }}
                      />
                      <button
                        type="submit"
                        className="btnSend"
                        disabled={!isInitialSet}
                        onClick={search_faq_vectordb}
                      >
                        <span><img src="https://img.hyundaidreamtour.com/images/hdvisaai/ico_sendMessage.svg" alt="Send" /></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="faqListD">
                  <div className="info">
                    <dl className="total number">
                      <p className="fontLightGray fontM">{totalText}</p>
                      <dt className="fontGray">Top</dt>
                      <dd className="fontBlack fontB">{total}</dd>
                    </dl>
                  </div>
                  {faqList.slice(0, 5).map((faq: iFaq, index) => {
                    let btnId = "acc" + index + "id";
                    let accId = "acc" + index;
                    let isAccOpen = accStates[index]
                    return (
                      <div className={`accorD faqList ${isAccOpen ? "is-open" : ""}`} key={index}>
                        <p className="ask accorHead">
                          <button

                            type="button"
                            aria-controls={accId}
                            aria-expanded={isAccOpen ? "true" : "false"}
                            id={btnId}
                            onClick={(e) => { toggleAccordion(index) }}>
                            {faq?.title}
                          </button>
                        </p>
                        <div className={`answer accorCont ${isAccOpen ? "is-open" : ""}`}
                          aria-labelledby={btnId} id={accId} role="region"
                          style={isAccOpen ? { overflow: "hidden", whiteSpace: 'pre-wrap' } : { display: "none", whiteSpace: 'pre-wrap' }}
                        >
                          {faq?.answer}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* // 세부정보 읽기 */}
        </div>
      </div>{/*// contents */}
    </div>{/*// container */}
    <LoadingCircle loading={!isInitialSet} />
  </>;
};

export default FaqPage;
