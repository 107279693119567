import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AttachFileViewer from "../../utils/attach/AttachFileViewer";
import { appConfig } from "../../config/Config";

export default function SafetyDetailRead() {
    const navigate = useNavigate();
    const location = useLocation();
    const { sk } = useParams();
    const attachFileViewerRef: any = useRef();
    const selectedItem = location.state.selectedItem;

    const handleGoBack = () => {
        navigate(-1);
    };

    const handlePrint = () => {
        window.print(); // 브라우저 인쇄 다이얼로그 열기
    };

    useEffect(() => {
    }, []);

    return (
        <>
            <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
                <div id="contents">
                    <div className="secCont board">
                        {/* 세부정보 읽기 */}
                        <div className="contBody">
                            <div className="contHeader">
                                <div className="tit">
                                    <a className="btnPrev" onClick={handleGoBack} style={{ cursor: 'pointer', }} >이전</a>
                                    <p>데이터 관리</p>
                                </div>
                            </div>
                            <div className="contBox">
                                <div className="boardInfo">
                                    <div className="info">
                                    </div>
                                    <div className="rightbtnArea">
                                        <a className="btnLineGray print" onClick={handlePrint} style={{ cursor: 'pointer', }} >인쇄</a>
                                    </div>
                                </div>
                                <div className="readD">
                                    <div className="rtitD">
                                        <div className="title">{selectedItem.country} {selectedItem.title} </div>
                                        <div className="date fontL fontLightGray">{selectedItem.day} {selectedItem.time}</div>
                                    </div>
                                    {selectedItem.prev_status === '1' ? (
                                        <>
                                            <div className="drcontD">
                                                <span className="label gray">기존</span>
                                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                                    {selectedItem.content}
                                                </p>
                                            </div>
                                            <div className="drcontD line">
                                                <span className="label blue">변경</span>
                                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                                    {selectedItem.new_content}
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="drcontD">
                                            {/* <span className="label gray">기존</span> */}
                                            <p style={{ whiteSpace: 'pre-wrap' }}
                                                dangerouslySetInnerHTML={{ __html: selectedItem.content }}
                                            >
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* // 세부정보 읽기 */}
                    </div>
                </div>{/*// contents */}
            </div>{/*// container */}
        </>
    )
}
