import { Box } from "@mui/material";
import { userState } from "../../interface/MainInterface";
import NoticeComponent from "../components/NoticeComponent";
import SafetyInfoComponent from "../components/SafetyInfoComponent";
import CountrySearchComponent from "../components/CountrySearchComponent";
import LatestVisaComponent from "../components/LatestVisaComponent";
import VisaConfirmComponent from "../components/VisaConfirmComponent";
interface propsType {
  userState: userState;
  setLeftUpdate: any;
}

const DashBoardPage = (props: propsType) => {

  return (
    <Box id="container">
      <Box id="contents">
        <Box className="main">
          <CountrySearchComponent />
          <LatestVisaComponent
            userState={props.userState}
            setLeftUpdate={props.setLeftUpdate}
          />
          {props.userState.isAdmin ? (
            <>
              <Box className="dashboard newsDash">
                <NoticeComponent 
                  itemNum = {3}
                />
                <SafetyInfoComponent 
                  itemNum = {3}  
                />
              </Box>
              <VisaConfirmComponent
                userState={props.userState}
              />
            </>
          ) : (
            <>
              <Box className="dashboard newsDash">
                <NoticeComponent 
                  itemNum = {10}
                />
              </Box>
              <Box className="dashboard newsDash">
                <SafetyInfoComponent 
                  itemNum = {10}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashBoardPage;
