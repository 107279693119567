import React, { useEffect, useRef, useState } from 'react'
import { iNotice } from '../../interface/VisaInterface';
import AttachFileViewer from '../../utils/attach/AttachFileViewer';
import S3Uploader from '../../utils/attach/S3Uploader';
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";

import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import S3RemoveAndUpload from '../../utils/attach/S3RemoveAndUpload';
import { useNavigate } from 'react-router-dom';
import LoadingCircle from '../../utils/LoadingCircle';
import { userState } from '../../interface/MainInterface';

const visaApi = new HttpCustomApi(
    window.location.hostname === appConfig.prodURL
      ? appConfig.REACT_APP_VISA_API_PROD
      : appConfig.REACT_APP_VISA_API_DEV
  );
interface propsType {
    propNotice: iNotice | undefined;
    onChange: Function;
    userState: userState;
}


export default function NoticeEditor(props: propsType) {

    const attachFileViewerRef: any = useRef();
    const navigate = useNavigate();

    const [notice, setNotice] = useState<iNotice | undefined>()
    const [files, setFiles] = useState<File[]>([]);
    const [isS3Uploaded, setIsS3Uploaded] = useState(false);
    const [isProps, setIsProps] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);;
    const [isLoading, setIsLoading] = useState(false)

    const handlePrevClick = () => {
        if (window.confirm("저장하지 않고 뒤로 가시겠습니까?")) {
            props.onChange();
        };
    }


    // props 존재유무에따라
    const setInit = () => {
        setIsLoading(true);
        if (props.propNotice === undefined) {
            handleNoticeProp('hidden_yn', 'Y');
        }
        else {
            setIsProps(true);
            setIsUpdate(true);
            setNotice(props.propNotice);
            // TODO : 수정할때 기존 첨부파일들 에러 
            // s3에 없는건 삭제, 배열에만있는건 s3로 업로드, 둘다있는건 그냥 패스
            const transformedFiles: File[] = props.propNotice.attach_files.map((attach_file: any) => {
                const fileExt = attach_file.FILE_NAME.split('.').pop();
                // 파일 객체 생성
                const file = new File(
                    [attach_file], // 파일 데이터
                    attach_file.FILE_NAME, // 파일 이름
                    // attach_file.FILE_SIZE, // 파일 사이즈
                    { type: `application/${fileExt}` } // 파일 타입
                );

                Object.defineProperty(file, 'isDummy', {
                    value: 'Y',
                    writable: false,
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(file, 'dummy_size', {
                    value: attach_file.FILE_SIZE,
                    writable: false,
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(file, 'dummy_url', {
                    value: attach_file.DOWNLOAD_URL,
                    writable: false,
                    enumerable: true,
                    configurable: true
                });
                // File 객체 반환
                return file;
            });
            setFiles(transformedFiles)
        }
        setIsLoading(false)
    }

    // 파일 드래그 앤 드랍
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
    };

    // 파일 올리기
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const uploadedFiles = Array.from(event.target.files);
            setFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
        }
    };

    // 파일 제거
    const attachFileViewerCallback = (payload: any) => {
        if (payload?.command === "delete") {
            setFiles(prevFiles => prevFiles.filter((file) => file.name !== payload.fileInfo.FILE_NAME));
        }
    };


    const padZero = (num: number, len: number = 2): string => {
        if (len === 2) {
            return num < 10 ? `0${num}` : `${num}`
        }
        else {
            return num < 10 ? `00${num}` : (num < 100 ? `0${num}` : `${num}`)
        }
    };

    // 등록 버튼
    const handleUpload = () => {
        if (notice?.title === undefined) {
            alert("제목을 설정해주세요.")
            return;
        }
        if (notice?.country === undefined) {
            alert("국가를 설정해주세요.")
            return;
        }
        if (!window.confirm("등록 하시겠습니까?")) return;
        setIsLoading(true)
        const now = new Date();
        const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
        handleNoticeProp("sk", formattedTime)
        if (window.location.hostname === appConfig.prodURL) {
            S3RemoveAndUpload(
                files,
                `notice/${formattedTime}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_PROD,
                fncUploadComplete
            )
        }
        else {
            S3RemoveAndUpload(
                files,
                `notice/${formattedTime}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_DEV,
                fncUploadComplete
            )
        }
    };

    // 수정 버튼
    const handleUpdate = () => {

        if (notice?.title === undefined) {
            alert("제목을 설정해주세요.")
            return;
        }
        else if (notice?.country === undefined) {
            alert("국가를 설정해주세요.")
            return;
        }
        if (!window.confirm("등록 하시겠습니까?")) return;
        setIsLoading(true)
        if (window.location.hostname === appConfig.prodURL) {
            S3RemoveAndUpload(
                files,
                `notice/${notice?.sk}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_PROD,
                fncUploadComplete)
        }
        else {
            S3RemoveAndUpload(
                files,
                `notice/${notice?.sk}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_DEV,
                fncUploadComplete)
        }
    };

    // 첨부파일 업로드 완료시 콜백
    const fncUploadComplete = (result: any) => {
        if (result.status === "ok") {
            setIsS3Uploaded(true)
            handleNoticeProp("attach_files", result.fileInfo)
        }
    };

    const handleNoticeProp = (key: string, value: string) => {
        setNotice(prevNotice => ({
            ...prevNotice,
            [key]: value
        }))
    }


    // file을 올렸을때 notice 임시추가
    useEffect(() => {
        const transformedFiles = files.map((file: any) => ({
            attach_range: new Date(file.lastModified).toLocaleDateString(),
            DOWNLOAD_URL: file.isDummy ? file.dummy_url : "",
            FILE_EXT: file.name.split('.').pop(), // 파일 확장자를 추출하여 설정
            FILE_NAME: file.name,
            FILE_PATH: "",
            FILE_SIZE: file.isDummy ? file.dummy_size : file.size,
        }));
        setNotice(prevNotice => ({
            ...prevNotice,
            attach_files: transformedFiles
        }));
    }, [files])

    useEffect(() => {
        if (isS3Uploaded) {
            if (isUpdate) {
                noticeUpdateDdb();
                setIsS3Uploaded(false);
                setIsLoading(false);
            }
            else {
                noticeUploadDdb();
                setIsS3Uploaded(false);
                setIsLoading(false);
            }
        }

    }, [isS3Uploaded, notice])


    useEffect(() => {
        setInit();
    }, [])

    // insert DDB
    const noticeUploadDdb = async () => {

        if (notice?.sk === undefined) {
            alert("등록에 실패하였습니다. 다시 한번 시도해주세요.");
            return;
        }

        const noticeparam: any = {
            command: "put_information",
            pk: "notice#visa",
            sk: notice.sk,
            attach_files: notice?.attach_files,
            content: notice?.content,
            country: notice?.country,
            editor: props.userState.name,
            hidden_yn: notice?.hidden_yn,
            title: notice?.title,
            update_dt: notice.sk,
        };


        const noticeset = await visaApi.common_request("adminMain", noticeparam);
        if (noticeset.code === "200") {
            alert("등록 완료");
            navigate(0)
        } else {
            alert("등록에 실패하였습니다. 다시 한번 시도해주세요.");
            return;
        }
    };

    // update DDB 덮어쓰기
    const noticeUpdateDdb = async () => {

        if (notice?.sk === undefined) {
            alert("등록에 실패하였습니다. 다시 한번 시도해주세요.");
            return;
        }

        const now = new Date();
        const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;

        const noticeparam: any = {
            command: "put_information",
            pk: "notice#visa",
            sk: notice.sk,
            attach_files: notice?.attach_files,
            content: notice.content,
            country: notice.country,
            editor: props.userState.name,
            hidden_yn: notice.hidden_yn,
            title: notice.title,
            update_dt: formattedTime,
        };


        const noticeset = await visaApi.common_request("adminMain", noticeparam);
        if (noticeset.code === "200") {
            alert("등록 완료");
            navigate(0)
        } else {
            alert("등록에 실패하였습니다. 다시 한번 시도해주세요.");
            return;
        }
    };


    return (
        <>
            <div className="contBody">
                <div className="contHeader">
                    <div className="tit">
                        <a className="btnPrev" onClick={handlePrevClick} style={{ cursor: "pointer" }}>이전</a>
                        <p>중요 공지 사항 등록</p>
                    </div>
                </div>
                <div className="contBox">
                    <div className="boardInfo">
                        <div className="info">
                        </div>
                        <div className="btnAreaR">
                            {/* <a className="btnLineGray" style={{ marginRight: "6px" }}>임시저장</a> */}
                            {isProps ? <a className="btnLineGray" onClick={handleUpdate} style={{ cursor: 'pointer' }}>수정완료</a>
                                : <a className="btnLineGray" onClick={handleUpload} style={{ cursor: 'pointer' }}>등록</a>}

                        </div>
                    </div>
                    <div className="writerD">
                        <div className="editTop">
                            <div className="wtitD">
                                <p>
                                    <label htmlFor="title">
                                        <span className="required">제목</span>
                                    </label>
                                    <input id="title" placeholder="제목을 입력해주세요." value={notice?.title} required
                                        onChange={(e) => { handleNoticeProp(e.target.id, e.target.value) }} />
                                </p>
                                <p>
                                    <label htmlFor="writer"  >
                                        <span className="required">메인노출여부</span>
                                    </label>
                                    <select id="hidden_yn" value={notice?.hidden_yn} onChange={(e) => { handleNoticeProp(e.target.id, e.target.value) }}>
                                        <option value="Y" >노출</option>
                                        <option value="N">미노출</option>
                                    </select>
                                </p>
                            </div>
                            <div className="wtitD">
                                <p>
                                    <label htmlFor="country">
                                        <span className="required">국가</span>
                                    </label>
                                    <input id="country" placeholder="국가를 입력해주세요." value={notice?.country} required
                                        onChange={(e) => { handleNoticeProp(e.target.id, e.target.value) }} />
                                </p>
                                <p>
                                    <label htmlFor="writer"  >
                                        <span >작성자</span>
                                    </label>
                                    <input id="wirter" value={props.userState.name} readOnly /> {/* 로그인 정보로 자동 연동 */}
                                </p>
                            </div>
                        </div>
                        <div className="eidtorD">
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={20}
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        '&:focus': {
                                            borderColor: 'transparent',
                                            backgroundColor: 'transparent'
                                        },
                                    },
                                    width: "100%", height: "100%"
                                }}
                                value={notice?.content}
                                onChange={(e) => { handleNoticeProp('content', e.target.value) }} />
                        </div>
                        {/* <input
                            type="file"
                            onChange={handleFileUpload}
                            multiple
                            style={{ display: 'none' }}
                            id="fileInput"
                        /> */}
                        <Box
                            onDrop={handleDrop}
                            onDragOver={(event) => event.preventDefault()}
                            sx={{ margin: "12px" }} >
                            {(window.location.hostname === appConfig.prodURL) ? (
                                <AttachFileViewer
                                    ref={attachFileViewerRef}
                                    rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_PROD}
                                    files={notice?.attach_files}
                                    canDelete={true}
                                    isBox={true}
                                    callback={attachFileViewerCallback}
                                />
                            ) : (
                                <AttachFileViewer
                                    ref={attachFileViewerRef}
                                    rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_DEV}
                                    files={notice?.attach_files}
                                    canDelete={true}
                                    isBox={true}
                                    callback={attachFileViewerCallback}
                                />
                            )}
                        </Box>
                    </div>
                </div>
            </div>


            <LoadingCircle loading={isLoading} />
        </>
    )
}
