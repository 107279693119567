import { useState, useEffect } from "react";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// import CountryDdbList from "./CountryDdbList";
import { countries } from "./CountryList";

interface propsType {
  fnSearch: (location: string | null, location_code: string | null) => void;
  location: string | null;
  setLocation: React.Dispatch<React.SetStateAction<string | null>>;
  location_code: string | null;
  setLocationCode: React.Dispatch<React.SetStateAction<string | null>>;
}

const CountrySearchMain = (props: propsType) => {
  const [isSetting, setIsSetting] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleBizEnter = (e: React.KeyboardEvent) => {
    // Shift + Enter를 누르면 줄바꿈을 합니다.
    if (isSetting) {
      if (e.key === "Enter" && e.shiftKey) {
        return;
      }

      // Enter를 누르면 메시지를 전송합니다.
      if (e.key === "Enter" && !e.nativeEvent.isComposing) {
        e.preventDefault();
        setEnterPressed(true);
      }
    }
  };

  useEffect(() => {
    if (enterPressed) {
      props.fnSearch(props.location, props.location_code);
      setIsSetting(false);
    }
    setEnterPressed(false);
  }, [enterPressed]);

  useEffect(() => {
    // console.log("location : ", props.location);
  }, [props.location]);

  return (
    <Box className="aiSrchDiv">
      <Autocomplete
        id="highlights-demo"
        options={countries}
        freeSolo
        value={props.location}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        onChange={(event, newValue) => {
          if (typeof newValue === "object" && newValue !== null) {
            props.setLocation(newValue.label);
            props.setLocationCode(newValue.code);
            setIsSetting(true);
          }
          // else if (typeof newValue === "string") {
          //   props.setLocation(newValue);
          //   const foundCountry = countries.find(country => country.label === newValue);
          //   if (foundCountry) {
          //     props.setLocationCode(foundCountry.code);
          //   } else {
          //     props.setLocationCode(null); // 만약 해당하는 국가가 없는 경우 코드를 null로 설정
          //   }
          //   setIsSetting(true);
          // }
          else {
            props.setLocation(null);
            props.setLocationCode(null);
            setIsSetting(false);
          }
        }}
        renderInput={(params) => (
          <Box
            className="search"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              border: "2px solid #1D81A5",
              borderRadius: "12px",
              padding: "0px !important",
              "&:focus-within": {
                background: "#FBFCFD",
              },
            }}
          >
            <TextField
              {...params}
              type="text"
              placeholder="국가명을 입력해주세요."
              value={props.location}
              onChange={(e) => {
                props.setLocation((location) => e.target.value);
                const foundCountry = countries.find(country => country.label === e.target.value);
                if (foundCountry) {
                  props.setLocationCode(foundCountry.code);
                  setIsSetting(true);
                }
              }}
              onKeyDown={handleBizEnter}
              sx={{
                width: "calc(100% - 34px)",
                background: "transparent",
                "& .MuiInputBase-input": {
                  border: "none",
                  background: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                  borderBottom: "none",
                },
              }}
            />
            <Button
              className="btnSrch"
              sx={{
                bgcolor: "transparent !important",
                WebkitFlexGrow: 0,
                flexGrow: 0,
              }}
              onClick={() => {
                props.fnSearch(props.location, props.location_code);
              }}
            >
              <img
                src="https://img.hyundaidreamtour.com/images/hdvisaai/btn_search.svg"
                alt="검색"
              />
            </Button>
          </Box>
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? "#1D81A5" : "inherit",
                      textDecoration: part.highlight ? "underline" : "none",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        PaperComponent={({ children }) => (
          <Box className="aiDash">
            <Box className="searchD">
              <Box className="aiSrchDiv">
                <Box
                  className="relatedTerms"
                  sx={{
                    position: "static !important",
                    width: "calc(100% + 70px) !important",
                  }}
                >
                  <Box className="group country">
                    <p className="gTit">국가</p>
                    <ul className="gList">{children}</ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      />
      {/* <CountryDdbList setParentCountries={setDtcountries} /> */}
    </Box>
  );
};

export default CountrySearchMain;
