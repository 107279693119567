import { Backdrop, Box } from "@mui/material";
import { userState } from "../../interface/MainInterface";
import { useEffect, useState } from "react";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { readLocal, saveLocal } from "../../utils/localstorage_utils";
import { useNavigate } from "react-router-dom";
import { QuestionCategory } from "../components/Category";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import LoadingCircle from "../../utils/LoadingCircle";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface propsType {
  userState: userState;
}

const PAGING_SIZE = 5; // 화면에 보여줄 페이징 수
const NOTICE_PER_PAGE = 10; // 한 화면에 보여줄 글 개수
const NOTICE_CNT = PAGING_SIZE * NOTICE_PER_PAGE; // 한번 ddb에서 가져올 글 개수

const selectList = QuestionCategory;

const MyAskPage = (props: propsType) => {
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState("");
  const [searchCondition, setSearchCondition] = useState("all");
  const [totalCnt, setTotalCnt] = useState(0);
  const [completCnt, setCompletCnt] = useState(0);
  const [watingCnt, setWatingCnt] = useState(0);
  const [myAskList, setMyAskList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopup, setIsPopup] = useState(false);
  const [popupCategory, setPopupCategory] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupInquiry, setPopupInquiry] = useState("");
  const [isInitialSet, setIsInitialSet] = useState(false);

  const padZero = (num: number, len: number = 2): string => {
    if (len === 2) {
      return num < 10 ? `0${num}` : `${num}`;
    } else {
      return num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`;
    }
  };

  // 일단 여기도 모두 가져오자
  const getMyAskList = async () => {
    const param: any = {
      command: "get_information_proj",
      pk: "myask#visa",
      projection: "pk, sk, title, category, editor, answer_state",
    };

    const visares = await visaApi.common_request("adminMain", param);
    if (visares.code === "200") {
      let myList: any = [];
      // 관리자 로그인이면 모두 보이기
      if (props.userState.isAdmin) {
        for (const myask of visares.response.result_list) {
          myList.push(myask);
        }
      }
      // 사용자면 본인 질문만 보이기
      else {
        for (const myask of visares.response.result_list) {
          if (props.userState.name === myask.editor) {
            myList.push(myask);
          }
        }
      }

      let answerNoCnt = 0;
      for (let ask of myList) {
        if (ask.answer_state === "N") {
          answerNoCnt++;
        }
      }
      setTotalCnt(myList.length);
      setCompletCnt(myList.length - answerNoCnt);
      setWatingCnt(answerNoCnt);

      // 정렬
      const sortedList = [...myList].sort((a, b) => {
        if (a.sk < b.sk) return 1;
        if (a.sk > b.sk) return -1;
        return 0;
      });
      const sortedListWithIdx = sortedList.map((item, index) => ({
        ...item,
        idx: sortedList.length - index,
      }));
      setMyAskList(sortedListWithIdx);
      if (readLocal(appConfig.myaskPageKey01) !== 1) {
        setCurrentPage(1);
      }
    }
    setIsInitialSet(true);
  };

  // 검색조건
  const filtereAskList = myAskList.filter((item: any) => {
    if (searchCondition === "title") {
      return item.title.toLowerCase().includes(searchKey.toLowerCase());
    } else if (searchCondition === "editor") {
      return item.editor.toLowerCase().includes(searchKey.toLowerCase());
    } else if (searchCondition === "all") {
      return item.title.toLowerCase().includes(searchKey.toLowerCase()) || item.editor.toLowerCase().includes(searchKey.toLowerCase());
    }
  });

  // 페이지 변경 핸들러
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const openPopup = () => {
    setIsPopup(true);
  };
  const closePopup = () => {
    setIsPopup(false);
  };

  const submitInquiry = async () => {
    if (popupCategory === "선택" || popupCategory === "") {
      alert("유형을 선택해주세요.");
      return;
    } else if (popupTitle === "") {
      alert("제목을 채워주세요.");
      return;
    } else if (popupInquiry === "") {
      alert("내용을 채워주세요.");
      return;
    }
    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    let param: any = {
      command: "put_information",
      pk: "myask#visa",
      sk: formattedTime + "#" + props.userState.id + "#" + props.userState.company,
      category: popupCategory,
      title: popupTitle,
      content: popupInquiry,
      editor: props.userState.name,
      update_dt: formattedTime,
      answer: "",
      answer_state: "N",
    };

    // 문의하기 전용 GSI
    param.myask_visa_pk = `myask#visa#${props.userState.id}#${props.userState.company}`;
    param.myask_visa_sk = formattedTime;

    const res = await visaApi.common_request("adminMain", param);
    alert("제출되었습니다");

    closePopup();
    setPopupCategory("");
    setPopupInquiry("");
    setPopupTitle("");
    navigate(0);
  };

  useEffect(() => {
    getMyAskList();

    const storedData = readLocal(appConfig.myaskSearchKey01);
    const storedPage = readLocal(appConfig.myaskPageKey01);
    if (storedData !== null && storedData !== "") {
      try {
        const recentSearchData = storedData;
        setSearchKey(recentSearchData);
        saveLocal(appConfig.myaskSearchKey01, "");
      } catch (error) {
        console.error("Parsing SearchKey error:", error);
      }
    }
    if (storedPage !== null && storedPage !== "") {
      try {
        const recentPageData = storedPage;
        setCurrentPage(recentPageData);
        saveLocal(appConfig.myaskPageKey01, 1);
      } catch (error) {
        console.error("Parsing PageKey error:", error);
      }
    }
    return () => {
      setSearchKey("");
      setCurrentPage(1);
    };
  }, []);

  return (
    <>
      <div id="container">
        <div id="contents">
          <div className="secCont board">
            {/* 세부정보 읽기 */}
            <div className="contBody">
              <div className="contHeader">
                <div className="tit">
                  나의 문의내역
                  {/*<a class="btnPrev">이전</a>
								<p class="nation">
									<img src="https://img.hyundaidreamtour.com/images/aihdt/flag/flag_in.png" alt="인도" >
									<span>인도</span>							
								</p>
 								<p class="date">2024-01-15 15:50</p>*/}
                </div>
              </div>
              <div className="contBox">
                <div className="boardInfo">
                  <div className="info">
                    <dl className="total number">
                      <dt className="fontGray">Total</dt>
                      <dd className="fontBlack fontB">{totalCnt}</dd>
                    </dl>
                    <dl className="total number">
                      <dt className="fontGray">답변완료</dt>
                      <dd className="fontBlue fontB">{completCnt}</dd>
                    </dl>
                    <dl className="total number">
                      <dt className="fontGray">답변대기</dt>
                      <dd className="fontRed fontB">{watingCnt}</dd>
                    </dl>
                  </div>
                  <div className="rightbtnArea">
                    <select
                      id="search_condition"
                      value={searchCondition}
                      onChange={(e) => {
                        setSearchCondition(e.target.value);
                      }}
                    >
                      <option value="all">전체</option>
                      <option value="title">제목</option>
                      <option value="editor">문의자</option>
                    </select>

                    <input
                      className="search"
                      id="search"
                      name="search"
                      placeholder="검색어를 입력해주세요."
                      value={searchKey}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                    {props.userState.isAdmin ? (
                      <></>
                    ) : (
                      <a className="plus btnBlueSmall btnPopOpen" onClick={openPopup} style={{ cursor: "pointer" }}>
                        질문추가
                      </a>
                    )}
                  </div>
                </div>
                <table className="boardD">
                  <colgroup>
                    <col className="num" />
                    <col className="title" />
                    <col className="category" />
                    {props.userState.isAdmin ? <col className="writer" /> : <></>}
                    <col className="state" />
                    <col className="date" />
                  </colgroup>
                  <thead>
                    <tr>
                      <td scope="col">번호</td>
                      <td scope="col">제목</td>
                      <td scope="col">문의유형</td>
                      {props.userState.isAdmin ? <td scope="col">작성자</td> : <></>}
                      <td scope="col">답변상태</td>
                      <td scope="col">등록일</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtereAskList
                      .slice((currentPage - 1) * NOTICE_PER_PAGE, (currentPage - 1) * NOTICE_PER_PAGE + NOTICE_PER_PAGE)
                      .map((myAsk: any, index: any) => {
                        if (true) {
                          return (
                            <tr
                              onClick={() => {
                                saveLocal(appConfig.myaskSearchKey01, searchKey);
                                saveLocal(appConfig.myaskPageKey01, currentPage);
                                navigate(`/myasklistread/${myAsk.sk}`, {
                                  state: {
                                    selectedItem: myAsk,
                                    tCnt: totalCnt,
                                    cCnt: completCnt,
                                    wCnt: watingCnt,
                                  },
                                });
                              }}
                              key={index}
                            >
                              {/* <td>{myAskList.length - (index + (NOTICE_PER_PAGE * (currentPage - 1)))}</td> */}
                              <td>{myAsk.idx}</td>
                              <td className="boardtit">
                                <a>{myAsk.title}</a>
                              </td>
                              <td>{myAsk.category}</td>
                              {props.userState.isAdmin ? <td>{myAsk.editor}</td> : <></>}
                              <td className={myAsk.answer_state === "Y" ? "fontBlue" : "fontRed"}>
                                <span>{myAsk.answer_state === "Y" ? "답변 완료" : "답변 대기"}</span>
                              </td>
                              {/* 미노출 시 class="blind" 추가 */}
                              <td>
                                {myAsk.sk.substring(0, 4)}-{myAsk.sk.substring(4, 6)}-{myAsk.sk.substring(6, 8)}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>

                <div className="pagination">
                  <Pagination
                    renderItem={(item) => <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />}
                    size="large"
                    className="page"
                    count={Math.ceil(filtereAskList.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  />
                </div>
              </div>
            </div>
            {/* // 세부정보 읽기 */}
          </div>
        </div>
        {/*// contents */}
      </div>
      {/*// container */}

      {isPopup && (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }} open>
            <div className="popupD popup860">
              <div className="popTop">
                <p className="popTit" style={{ color: "black" }}>
                  담당자 문의
                </p>
                <a className="btnPopClose" style={{ cursor: "pointer" }} onClick={closePopup}>
                  닫기
                </a>
              </div>
              <div className="popCont">
                <div className="formD inquiry">
                  <div className="line">
                    <div className="tdD w224">
                      <p className="lineTitle">
                        <label htmlFor="name">
                          <span className="required">문의자</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <input id="name" className="wFull" defaultValue={props.userState.name} readOnly />
                      </p>
                    </div>
                    <div className="tdD w540">
                      <p className="lineTitle">
                        <label>
                          <span className="required">문의유형</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <select
                          className="wFull"
                          value={popupCategory}
                          onChange={(e) => {
                            setPopupCategory(e.target.value);
                          }}
                        >
                          <option value="">-- 선택 --</option>
                          {selectList.map((option: string) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </p>
                    </div>
                  </div>
                  <div className="line">
                    <p className="lineTitle">
                      <label>
                        <span>제목</span>
                      </label>
                    </p>
                    <p className="lineWrite">
                      <input
                        className="wFull"
                        value={popupTitle}
                        required
                        onChange={(e) => {
                          setPopupTitle(e.target.value);
                        }}
                      />
                    </p>
                  </div>
                  <div className="line">
                    <p className="lineTitle">
                      <label>
                        <span>문의내용</span>
                      </label>
                    </p>
                    <p className="lineWrite">
                      <textarea
                        className="wFull inquiryTextarea"
                        required
                        value={popupInquiry}
                        onChange={(e) => {
                          setPopupInquiry(e.target.value);
                        }}
                        style={{ resize: "none" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="popbtnArea centerbtnArea">
                  <button type="submit" className="btnBlue" onClick={submitInquiry}>
                    문의하기
                  </button>
                </div>
              </div>
            </div>
          </Backdrop>
        </>
      )}
      <LoadingCircle loading={!isInitialSet} />
    </>
  );
};

export default MyAskPage;
