import { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";

import { HttpCustomApi } from "../../interface/custom-api";
import { appConfig } from "../../config/Config";
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import Toast from "../../utils/Toast";

import "./Login.css";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

const DreamtourLogin = () => {
  const toastRef: any = useRef();

  const [userCode, setUserCode] = useState("");
  const [siteName, setSiteName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);

  // 문자열을 AES-256로 암호화하는 함수
  const encryptAES = (text: string): string => {
    return AES.encrypt(text, Hex.parse(appConfig.AES_KEY), { iv: Hex.parse(appConfig.AES_IV) }).toString();
  };
  // AES-256로 암호화된 문자열을 복호화하는 함수
  const getUserKey = (encryptedText: string): string => {
    const bytes = AES.decrypt(encryptedText, Hex.parse(appConfig.AES_KEY), { iv: Hex.parse(appConfig.AES_IV) });
    let decryptedString;
    try {
        decryptedString = bytes.toString(Utf8);
    } catch (error) {
        toastRef.current?.toast("인증키를 확인해 주세요.", "warning", 3000, {
          vertical: "top",
          horizontal: "center",

        });
        console.error("Error while decoding UTF-8:", error);
        decryptedString = ''; // 빈 문자열 또는 다른 기본값으로 설정할 수 있습니다.
    }
    return decryptedString;
  };
  // 256 비트(32 바이트) AES 키 생성, 128 비트(16 바이트) IV 생성
  const getRandNum = () => {
    const AES_KEY = generateRandomHexString(32);
    const AES_IV = generateRandomHexString(16);
  }
  // 무작위 Hex 문자열 생성 함수
  function generateRandomHexString(length: number): string {
    const values = new Uint8Array(length);
    window.crypto.getRandomValues(values);
    return Array.from(values).map(byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');
  }
  // 로그인 가능 시간 계산
  function checkKeyValid(dateTime: string): boolean {
    // 주어진 문자열에서 연, 월, 일, 시, 분, 초를 추출
    const year = parseInt(dateTime.substring(0, 4));
    const month = parseInt(dateTime.substring(4, 6)) - 1; // JavaScript Date 객체는 월을 0부터 시작하므로 1을 빼줍니다.
    const date = parseInt(dateTime.substring(6, 8));
    const hours = parseInt(dateTime.substring(8, 10));
    const minutes = parseInt(dateTime.substring(10, 12));
    const seconds = parseInt(dateTime.substring(12, 14));
  
    // 주어진 문자열을 Date 객체로 변환
    const targetTime = new Date(year, month, date, hours, minutes, seconds);
  
    // 현재 시간을 가져와서 차이를 계산
    const now = new Date();
    const differenceInSeconds = Math.abs((now.getTime() - targetTime.getTime()) / 1000);
    const differenceInMinutes = differenceInSeconds / 60;
    
    return differenceInMinutes > 5; // 5분 보다 크면 true, 로그인 불가능
  }

  // rds 통신해서 람다 호출
  const check_user_db = async (key_id: string) => {
    
    let dateTime: string = ""; // "20240325161126"
    let siteName: string = ""; // "hdream"
    let companyName: string = ""; // "현대아이티앤이"
    let userId: string =""; // "2014748"
    let userName: string =""; 
    let validFlag: boolean = false;

    if(key_id === "1724621" || key_id === "2219217" || key_id === "2014748"
      || key_id === "1727822" || key_id === "0900095") {//admin은 h드림 안통하고 바로 로그인가능하도록 처리
      userId = key_id;
      companyName = "현대드림투어";
    }
    else {
      const parts: string[] = key_id.split(":");
      dateTime = parts[0]; // "20240325161126"
      siteName = parts[1]; // "Hbiz"
      companyName = parts[2]; // "현대아이티앤이"
      userId = parts[3]; // "2014748"
      if ( parts.length > 4 ) {
        userName = parts[4]; // "이민호"
      }
      validFlag = checkKeyValid(dateTime);
  
      if(validFlag) {
        toastRef.current?.toast("유효하지 않는 접근입니다.", "warning", 3000, {
          vertical: "top",
          horizontal: "center",
        });
        console.log("유효하지 않는 접근입니다.");
        return;
      }

    }
    setSiteName(siteName);
    setCompanyName(companyName);
    setUserId(userId);
    setUserName(userName);
    
    /*
    let userId: string ="";
    let companyName: string ="";

    if(key_id == "1724621" || key_id == "2219217"|| key_id == "2014748"
      || key_id === "1727822" || key_id === "0900095"){//admin은 h드림 안통하고 바로 로그인가능하도록 처리
      userId = key_id;
    }else{
      const parts: string[] = key_id.split(":");
      const dateTime: string = parts[0]; // "20240325161126"
      companyName = parts[1]; // "현대아이티앤이"
      userId = parts[2]; // "2014748"
      const validFlag = checkKeyValid(dateTime);
  
      if(validFlag) {
        toastRef.current?.toast("유효하지 않는 접근입니다.", "warning", 3000, {
          vertical: "top",
          horizontal: "center",
        });
        console.log("유효하지 않는 접근입니다.");
        return;
      }
      
    }
    */

    let parameter : any = "";
    let response : any = "";

    if (userName !== "") {
      parameter = {
        command: "put_user",
        pk: companyName,
        sk: userId,
        name: userName,
        image_url: "",
        user_info1: companyName,
        user_info2: companyName,
        user_info3: companyName,
        user_info4: companyName,
        user_type: 3,
        site_name: siteName,
      }
      response = await visaApi.common_request("adminMain", parameter);
      if (response.code !== "200") {
        toastRef.current?.toast(response.result_msg, "error", 3000, {
          vertical: "top",
          horizontal: "center",
        });
      }
    }

    const param: any = {
      command: "login_proc",
      pk: companyName,
      sk: userId,
    };

    const res = await visaApi.common_request("adminMain", param);
    if ("" + res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
      else procFail(res.response);
    } else {
      toastRef.current?.toast(res.response.result_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    toastRef.current?.toast("Welcome. Enjoy 드림투어AI", "success", 4000, { vertical: "top", horizontal: "center" });
    const sessionObj: any = {
      id: sessionData.id,
      userType: sessionData.user_type,
      imageUrl: sessionData.image_url,
      name: sessionData.user_name,
      company: sessionData.user_info1,
      expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
    };
    const objString = JSON.stringify(sessionObj);
    const encObjString = AES.encrypt(objString, appConfig.seed).toString();
    window.localStorage.setItem(appConfig.sessionName, encObjString);
    window.location.href = "/";
  };
  // 로그인 실패
  const procFail = (res: any) => {
    toastRef.current?.toast(res.result_msg, "error", 3000, {
      vertical: "top",
      horizontal: "center",
    });
  };

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  // useEffect(() => {
  //   if (isLoading) {
  //     setIsDisplay(true);
  //     setIsLoading(false);
  //   }
  // }, [isLoading]);

  //https://dev.ai-hyundaidreamtour.com/login?requestKey=ouyuYx+8vFX4WSvHaWD1DBp6FiOFa1t15XS8QuNHc7KryFpIUoK3vSn3k6P62a1T
  //http://localhost:3000/login?requestKey=K9HhDViC1EjnLm3ykSJInrQ6m4B5+xj7isWFl28c28swtqfQQalWGrz6N+7dApPz
  useEffect(() => {
    // const tmp_text = "20240408164600:현대아이티앤이:2014748";
    // let requestKey: string = encryptAES(tmp_text);
    window.history.replaceState({}, "", window.location.pathname);
    const requestKey = stateInfo.get('requestKey');

    if (requestKey) {
      if(requestKey == "1724621" || requestKey == "2219217" || requestKey == "2014748" 
        || requestKey === "1727822" || requestKey === "0900095"){
        check_user_db(requestKey);
      }else{
        const decodedRequestKey = requestKey.replace(/\s/g, '+');
        const keyInfo: string = decodedRequestKey;
        const customKey = getUserKey(keyInfo);
        check_user_db(customKey);
      }
    }
    else {
      toastRef.current?.toast("인증키를 확인해 주세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    
    // 0.1초 후에 isLoading을 true로 설정
    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    // 컴포넌트가 언마운트되기 전에 타임아웃을 제거하여, 타임아웃이 발생하기 전에 컴포넌트가 사라지면 isLoading을 true로 설정하지 않도록 합니다.
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="login-root">
      <div className="login-wrapper">
        <div className="login-box">
          <h2>
            <img src="https://img.hyundaidreamtour.com/images/aihdt/loading_ai.svg" alt="logo" />
          </h2>
          {isLoading === true ? (
            <Stack spacing={2} direction="column"
              sx={{
                marginTop: "50px",
              }}
            >
              <p>현대드림투어AI에 오신걸 환영해요.</p>
              <div className="form-wrap">
                {" "}
                H드림을 통해서 접속해주시기 바랍니다.
              </div>
            </Stack>
          ):(
            <Stack spacing={6} direction="column"
              sx={{
                marginTop: "50px",
              }}
            >
              <p>{" "}</p>
              <div className="form-wrap">
                {" "}
                {" "}
              </div>
            </Stack>
          )}
        </div>
        <div className="copy-warapper">
          <p className="copyright">© HYUNDAI DREAM TOUR All Right Reserved.</p>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default DreamtourLogin;
