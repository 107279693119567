import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import { userState } from "../../interface/MainInterface";
import { HttpCustomApi } from "../../interface/custom-api";

import LoadingCircle from "../../utils/LoadingCircle";
import { appConfig } from "../../config/Config";
interface propsType {
  userState: userState;
  setLeftUpdate: any;
}
interface LatestVisaType {
  sk:string;
  content: string;
  country: string;
  day: string;
  new_content: string;
  time: string;
  title: string;
  url: string;
  status: string;
}

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

const LatestVisaComponent = (props: propsType) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<string | null>>([]);
  const [latestList, setLatestList] = useState<LatestVisaType[]>([]);
  
  // 선택이 변경될 때 호출되는 함수
  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const selectedValue = event.target.value;
    let status;
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = selectedValue;
    setSelectedOptions(newSelectedOptions);

    if (selectedValue === '연동') {
      status = '100';
    }
    else if (selectedValue === '수정') {
      status = '101';
    }
    else {
      status = '102';
    }

    const today: Date = new Date();
    const year: string = today.getFullYear().toString();
    const month: string = (today.getMonth() + 1).toString().padStart(2, '0');
    const day: string = today.getDate().toString().padStart(2, '0');
    const formattedDate: string = `${year}-${month}-${day}`;

    const param: any = {
      command: "update_information_rpa_sk",
      pk: "rpa#visa", // pk
      sk: latestList[index].sk, // sk
      update_items: {
        prev_status: latestList[index].status,
        status: status,
        worker: props.userState.name,
        day: formattedDate,
      }
    };
    const res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      setIsUpdating(true);
      props.setLeftUpdate(true);
    }
  };

  const latestVisaList = async () => {
    let _maxCount: number = 3;
    let idx: number = 1;
  
    const param: any = {
      command: "get_information_latestvisa_filter",
      pk: "rpa#visa",
      max_count: _maxCount, // 안쓰는 파라미터
    };
  
    const res = await visaApi.common_request("adminMain", param);
  
    if (res.code === "200") {
      const searchResult = res.response.result_list;
      for (const source of searchResult) {
        let hour: string = source.time.split(":")[0];
        const min: string = source.time.split(":")[1];
        let period: string;

        if (parseInt(hour) < 12) {
          period = "AM";
        } else {
          period = "PM";
        }
        if (parseInt(hour) === 0) {
          hour = "12"; // 0시를 12시로 표시
        } else if (parseInt(hour) > 12) {
          hour = (parseInt(hour) - 12).toString().padStart(2, "0"); // 오후 시간을 12시간 형식으로 표시
        } else {
          hour = hour.padStart(2, "0"); // 한 자리 숫자인 경우 앞에 0 추가
        }

        source.time = `${hour}:${min} ${period}`;
      }

      searchResult.sort((a: LatestVisaType, b: LatestVisaType) => {
        if (a.day > b.day) return -1;
        if (a.day < b.day) return 1;
        // day가 같다면 time으로 정렬
        if (a.time > b.time) return -1;
        if (a.time < b.time) return 1;
        return 0;
      });

      setLatestList(searchResult);
    }
    else {
      setLatestList([]);
    }
    setIsLoading(true);
    setIsUpdating(false);
  };

  useEffect(() => {
    if (isUpdating) {
      latestVisaList();
    }
  }, [isUpdating]);
  useEffect(() => {
    setSelectedOptions(new Array(latestList.length).fill(null));
  }, [latestList]);
  useEffect(() => {
    latestVisaList();
  }, []);

  return (
    <>
      <div className="dashboard visaDash">
        <div className="tit">
          <h2>
            최신 비자 정보
            <p className="number fontR">
              <i className="fontB">{latestList.length}</i> 건
            </p>
          </h2>
          {props.userState.isAdmin? (
            <div className="rightBtnArea">
              <a className="btnMore"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/aidata`)
              }}>
                더보기
              </a>
            </div>
          ):(
            <></>
          )}
        </div>
        <div className="visaNewsD">
          <div className="scroll">
            {latestList.map((item: any, index) => (
              <div className="postD" key={index}>
                <p className="date fontLightGray">
                  <span className="yymmdd">{item.day}</span>
                  <span className="time">{item.time}</span>
                </p>
                <div className="post">
                  {/* 수정한 경우 class="modify" 추가 */}
                  <div className="postTop">
                    <div className="brief">
                      <p className="summary">{item.country} {item.title}</p>
                      <p className="source">by {item.country}</p>
                    </div>
                    {props.userState.isAdmin? (
                      <select
                        className="seleSt01 setup"
                        id={`latest_opt_${index}`}
                        name={`latest_opt_${index}`}
                        onChange={(event) => handleSelectChange(event, index)}
                        value={selectedOptions[index] || ''}
                      >
                        <option disabled value="">선택</option>
                        <option>연동</option>
                        <option>수정</option>
                        <option>무시</option>
                      </select>
                    ):(
                      <></>
                    )}
                  </div>
                  {item.status === '1' ? (
                    <div className="postCont modify">
                      <div className="box gray">
                        <span className="label gray">기존</span>
                        <p>
                          {item.content}
                        </p>
                      </div>
                      <div className="box blue">
                        <span className="label blue">변경</span>
                        <p>
                          {item.new_content}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="postCont">
                      <div className="box gray">
                        <p>{item.content}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoadingCircle loading={!isLoading} />
    </>
  );
};

export default LatestVisaComponent;
