import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircleGraph = ({ percent, duration }: { percent: number; duration: number }) => {

    // const [progress, setProgress] = useState(0)

    const fillcolor = percent <= 50 ? "#B60000" : "#1D81A5"
    let progress = 0
    // const [progress, setProgress] = useState(0)
    while (progress < percent) {
        // setProgress(progress + percent / duration); 
        progress += percent / duration
    }
    // console.log("percent progress : ", percent, progress)


    // function updateProgress() {
    //     if (progress < percent) {
    //         progress += percent / duration;
    //         // 여기서 색상 업데이트 또는 그래픽을 그리는 로직을 추가할 수 있습니다.
    //         console.log("Progress:", progress); // 예시로 콘솔에 진행 상황 출력
    //         setTimeout(updateProgress, duration); // 재귀적으로 자신을 호출하여 반복
    //     }
    // }

    // updateProgress(); // 함수 호출로 시작

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (progress < percent) {
    //             progress += percent / duration
    //             // if (100 <= progress + percent / duration) {
    //             //     setProgress(100.0);
    //             // }
    //             // else {
    //             //     setProgress(progress + percent / duration);
    //             // }
    //         }
    //         // setProgress(prevProgress => {
    //         //     if (prevProgress < percent) {
    //         //         return prevProgress + percent / duration;
    //         //     } else {
    //         //         clearInterval(interval);
    //         //         return prevProgress;
    //         //     }
    //         // });
    //     }, 10);
    //     return () => clearInterval(interval);
    // }, [progress, percent]);

    return (
        <CircularProgressbar
            value={progress}
            text={`${progress.toFixed(1)}%`}
            strokeWidth={4}
            styles={buildStyles({
                textSize: '26px',
                pathTransitionDuration: 0.1,
                pathColor: fillcolor,
                textColor: fillcolor,
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
            })}
        />
    );
};


export default CircleGraph;


