import { useEffect, useRef, useState, forwardRef } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";


AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});


const S3RemoveAndUpload = async (files: any, dir: any, BucketName: any, callBack: any) => {
  const s3 = new AWS.S3();
  const padZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

  // files에는 없고 s3에 있는 데이터 삭제
  const data = await s3.listObjectsV2({ Bucket: BucketName, Prefix: dir }).promise();
  const s3Files = data.Contents?.map((obj) => obj.Key) || [];
  const filesToDelete = s3Files.filter((s3File: any) => {
    const s3FileName = s3File.split('/').pop() || '';
    return !files.some((file: any) => file.name === s3FileName);
  });
  for (const file of filesToDelete) {
    if (file === undefined) continue
    s3.deleteObject({ Bucket: BucketName, Key: file }, function (err, data) {
      if (err) {
        console.log("delete fail: ", err)
        callBack({ status: "fail" });
        return;
      }
    });
  }

  // 하나씩 s3 put
  for (let file of files) {
    // 만약 기존꺼면 넘어가기
    if (typeof file.isDummy !== 'undefined' && file.isDummy === 'Y') continue;
    const fileKey = dir + file.name;
    let fileExtension = file.name.split(".").pop();
    if (fileExtension === "xlsx") {
      fileExtension = "xls";
    }

    const params = {
      Body: file,
      Bucket: BucketName,
      Key: fileKey,
    };
    const res = await s3.putObject(params).promise();
    if (res.$response.httpResponse.statusCode === 200) {
      console.log('upload success', res)
    } else {
      console.log("upload fail")
      callBack({ status: "fail" });
      return;
    }
  }

  // 하나씩 get 하기
  let result_list: any = [];
  for (let file of files) {
    const fileKey = dir + file.name;
    const fileData = await s3.getObject({ Bucket: BucketName, Key: fileKey }).promise();
    const fileSize = fileData.ContentLength;
    let fileExtension = fileKey.split('.').pop();
    if (fileExtension === "xlsx") {
      fileExtension = "xls";
    }
    // 다운로드 url
    const url_params = {
      Bucket: BucketName,
      Key: fileKey,
      Expires: 1200,
    };
    const downloadUrl = await s3.getSignedUrl("getObject", url_params);
    // 현재시간
    const now = new Date();
    const formattedTime = `${now.getFullYear()}-${padZero(now.getMonth() + 1)}-${padZero(now.getDate())}`;
    // 원하는 데이터 채우기
    result_list = [
      ...result_list,
      {
        FILE_NAME: file.name,
        FILE_PATH: fileKey,
        attach_range: formattedTime,
        FILE_SIZE: fileSize,
        FILE_EXT: fileExtension,
        DOWNLOAD_URL: downloadUrl,
      },
    ];
  }
  callBack({ status: "ok", fileInfo: result_list });

}
export default (S3RemoveAndUpload);
