import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import AWS from "aws-sdk";
import remarkGfm from "remark-gfm";

import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import { Avatar, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import StopCircleIcon from "@mui/icons-material/StopCircle";

import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { HttpAiApi } from "../../interface/ai-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import { ChatSetProps, CountryType, SearchResultItem } from "../../interface/VisaInterface";
import { countries } from "../components/CountryList";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { QuestionCategory, ChatCategory } from "../components/Category";
import { off } from "process";

const aiApi = new HttpAiApi();

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

interface ChatContentProps {
  ID: string;
  COUNTRY: string;
  FLAG: string;
  RANGE: string;
  ANSWER: string;
  QUESTION: string;
  READ_FLAG?: boolean;
  CATEGORY?: string;
  CONTACT?: string;
  EDITOR?: string;
  INQUIRY?: string;
  STATUS?: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface propsType {
  userState: userState;
}

AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const chatSelectList = ChatCategory;
const selectList = QuestionCategory;

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const VisaAiChatPage = (props: propsType) => {
  let _maxCount: number = 3;
  let pocid = props.userState.id;
  const inputBaseRef = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [location_flag, setLocationFlag] = useState("");
  const [location_code, setLocationCode] = useState("");
  const [question, setQuestion] = useState("");
  const [chatcontentList, setchatcontentList] = useState<ChatContentProps[]>([]);
  const [chatsetList, setchatsetList] = useState<any>([]);
  const [chatsetoldList, setchatsetoldList] = useState<any>([]);
  const [dataList, setdataList] = useState<any>([]);
  const [visaData, setVisaData] = useState(0);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isRecivedLocation, setIsRecivedLocation] = useState(false);
  const [isInitialSet, setIsInitialSet] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);
  const [countryClick, setCountryClick] = useState(false);
  const [isAutoChatOpen, setIsAutoChatOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [dtcountriesinfo, setdtcountriesinfo] = useState<SearchResultItem[]>([]);
  const [isInputBaseActive, setIsInputBaseActive] = useState(false);
  const [currentTime, setCurrentTime] = useState<string>("");
  const [blogTitle, setBlogTitle] = useState("");
  const [streamReader, setStreamReader] = useState<ReadableStreamDefaultReader | null>(null);
  const [isCalledCreateAns, setIsCalledCreateAns] = useState(true);
  const [scrollHeight, setScrollHeight] = useState(`${window.innerHeight * 0.9}px`);
  const [likeState, setLikeState] = useState<boolean[]>(Array(chatsetList.length).fill(undefined));

  const [isPopup, setIsPopup] = useState(false);
  const [popupRange, setPopupRange] = useState("");
  const [popupContact, setPopupContact] = useState("");
  const [popupCategory, setPopupCategory] = useState("");
  const [popupInquiry, setPopupInquiry] = useState("");
  const [popupIdx, setPopupIdx] = useState(0);

  const [isPopup2, setIsPopup2] = useState(false);
  const [popupCategory2, setPopupCategory2] = useState("");
  const [popupTitle2, setPopupTitle2] = useState("");
  const [popupInquiry2, setPopupInquiry2] = useState("");

  const [todayFlag, setTodayFlag] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);

  const closePopup = () => {
    setIsPopup(false);
    setIsPopup2(false);
  };

  const getChatSet = async () => {
    const visainfoparam: any = {
      command: "get_visachatset",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const visares = await visaApi.common_request("adminMain", visainfoparam);

    if (visares.code === "200") {
      const searchResult: ChatSetProps[] = visares.response.result_list;
      let source_list: ChatSetProps[] = [];
      let source_list_old: ChatSetProps[] = [];
      for (const source of searchResult) {
        if (source.RANGE.includes("어제") || source.RANGE.includes("오늘")) {
          source_list.push(source);
        } else {
          source_list_old.push(source);
        }
      }
      setchatsetList(source_list);
      setchatsetoldList(source_list_old);
    }
    setIsInitialSet(true);
  };

  const addChatSet = async () => {
    let country_bool = true;
    let country_it = false;
    setLocationFlag(`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`);

    for (const chat of chatsetList) {
      if (chat.COUNTRY === location) {
        country_bool = false;
        setIsAutoChatOpen(true);
      }
    }

    if (country_bool) {
      for (const dtcountry of countries) {
        if (dtcountry.label === location) {
          country_it = true;
        }
      }

      if (country_it) {
        const chatcountry = {
          ID: pocid, // 사용자 ID 자리 수정 필요
          COUNTRY: location,
          CHAT: "",
          RANGE: "",
          FLAG: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
        };
        chatsetList.unshift(chatcountry);
        setchatsetList(chatsetList);
        setIsAutoChatOpen(true);
      } else {
        window.alert("지원하지는 국가명입니다. 국가명을 다시 확인해주세요.");
      }
    }
  };

  const chatOpen = async (country: string, flag_url: string) => {
    setLocation(country); // 선택된 값을 설정
    setLocationFlag(flag_url);
    setLocationCode(flag_url.substring(56, 58)); // 선택된 값을 설정
    setIsAutoChatOpen(false);

    const visachatsetparam: any = {
      command: "set_visachatset",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: country,
      flag_url: flag_url,
      max_count: _maxCount, // 안쓰는 파라미터
    };
    const visaset = await visaApi.common_request("adminMain", visachatsetparam);
    if (visaset.code === "200") {
      setTodayFlag(visaset.response.to_flag);
    }

    const visachatcontentparam: any = {
      command: "get_visachatcontent",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: country,
      flag: flag_url,
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const visacont = await visaApi.common_request("adminMain", visachatcontentparam);

    if (visacont.code === "200") {
      const searchResult: ChatContentProps[] = visacont.response.result_list;
      let source_list: ChatContentProps[] = [];
      for (const source of searchResult) {
        source_list.push(source);
      }
      setchatcontentList(source_list);
    }
    setIsDetailLoading(true);
  };

  // LLM 생성
  const searchMain = async () => {
    if (!location) {
      return; // question이 null이면 함수 종료
    }
    setdataList([]);
    setBlogTitle(""); // 기존 답변 초기화
    if (streamReader !== null) {
      streamReader.cancel();
      streamReader.releaseLock();
      setStreamReader(null);
    }

    const param = {
      command: "get_visa_sk",
      pk: "entry#visa",
      sk: location,
    };

    const visares = await visaApi.common_request("adminMain", param);

    if (visares.code === "200") {
      const source_list = {
        COUNTRY: visares.response.result[0]["country"],
        NO_VISA: visares.response.result[0]["no_visa"],
        VISA_ARRIVAL: visares.response.result[0]["visa_on_arrival"],
        BUSINESS_VISA: visares.response.result[0]["business_visa"],
        TWOV_CONDITION: visares.response.result[0]["twov_condition"],
        REMAIN_PASSPORT: visares.response.result[0]["remain_valid_passport"],
        EMERGENCY_PASSPORT: visares.response.result[0]["emergency_passport"],
        TRAVEL_CERTIFICATE: visares.response.result[0]["travel_certificate"],
        ETC: visares.response.result[0]["etc"],
        REMARK: visares.response.result[0]["remark"],
        DETAIL: visares.response.result[0]["detail_information"],
        DATA_SOURCE: visares.response.result[0]["data_source"],
        ATTACHED: visares.response.result[0]["attach_source"],
        RANGE: visares.response.result[0]["range"],
        COUNTRY_INFO: visares.response.result[0]["country_info"],
        NOTE: visares.response.result[0]["note"],
        ATTACH_FILES: visares.response.result[0]["attach_files"],
      };
      setdataList(source_list);
      setIsMainLoading(true);
    }
  };

  const stopPressRelease = () => {
    if (streamReader !== null) {
      streamReader.cancel();
      streamReader.releaseLock();
      setStreamReader(null);
    }
  };

  // LLM 생성
  const searchQuestuion = async () => {
    if (!question) {
      return; //  null이면 함수 종료
    }
    setIsDetailLoading(false);
    setIsCalledCreateAns(false);

    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    const visachatsetparam: any = {
      command: "put_visachatcontent_question",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: location,
      flag_url: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
      question: question,
      // currentTime: currentTime,
      currentTime: formattedTime,
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const visaset = await visaApi.common_request("adminMain", visachatsetparam);

    if (visaset.code === "200") {
      const chatcontprops: ChatContentProps = {
        ID: pocid, // 사용자 ID 자리 수정 필요
        COUNTRY: location,
        FLAG: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
        RANGE: formattedTime,
        ANSWER: "",
        QUESTION: question,
        READ_FLAG: true,
      };
      let source_list: ChatContentProps[] = [];
      for (const source of chatcontentList) {
        source_list.push(source);
      }
      source_list.push(chatcontprops);
      setchatcontentList(source_list);
    }
    setIsLoading(true);

    const 국가명 = [dataList.COUNTRY];
    const 무비자입국A = [dataList.NO_VISA];
    const 무비자입국B = [dataList.VISA_ARRIVAL];
    const 상용비자 = [dataList.BUSINESS_VISA];
    const 통과여객조건 = [dataList.TWOV_CONDITION];
    const 여행증명서조건A = [dataList.REMAIN_PASSPORT];
    const 여행증명서조건B = [dataList.EMERGENCY_PASSPORT];
    const 여행증명서조건C = [dataList.TRAVEL_CERTIFICATE];
    const 여행증명서조건D = [dataList.ETC];
    const 유의사항 = [dataList.REMARK];
    const 구비서류 = [dataList.DETAIL];
    const 대사관주소 = [dataList.DATA_SOURCE];
    const 첨부파일출처 = [dataList.ATTACHED];
    const 국가정보 = [dataList.NOTE]; //비고란을 국가정보로 변경하였기 때문에 NOTE가 국가정보
    const 비고 = [dataList.COUNTRY_INFO]; //현재 COUNTRY_INFO를 비고로 사용 중

    let attach_contents: string = "";
    for (let file of dataList.ATTACH_FILES) {
      // const url_params = {
      //   Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      //   Key: file.FILE_PATH,
      //   Expires: 1200,
      // };
      // const downloadUrl = await s3.getSignedUrl("getObject", url_params);
      const encodedFilepath = encodeURIComponent(file.FILE_PATH);
      let S3BucketName;
      let S3ImageURL;
      let downloadUrl;
      if (window.location.hostname === appConfig.prodURL) {
        S3ImageURL = process.env.REACT_APP_S3_IMAGE_URL_PROD;
        downloadUrl = "https://" + S3ImageURL + encodedFilepath;
      } else {
        S3BucketName = process.env.REACT_APP_S3_BUCKET_NAME_DEV;
        downloadUrl = "https://" + S3BucketName + ".s3.ap-northeast-2.amazonaws.com/" + encodedFilepath;
      }

      attach_contents += "첨부파일 이름: " + file.FILE_NAME + ", 첨부파일 다운로드 URL: " + downloadUrl + "\n";
    }

    let detail_contents: string = "";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 비자 상담사로 " +
    //   국가명.join(", ") +
    //   " 비자 상담만 진행합니다." +
    //   "\n";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 담당이므로 다른 국가의 비자 상담은 할 수 없다." +
    //   "\n";
    // detail_contents +=
    //   "너가 비자 상담을 위해 알고 있는 지식은 아래와 같다.\n";
    detail_contents += "1. 국가명: " + 국가명.join(", ") + "\n";
    detail_contents += "2. 일반 여권 소지자 비자 필요 여부: " + 무비자입국A.join(", ") + "\n";
    // detail_contents +=
    //   "무비자란 비자가 필요 없다는 뜻입니다. 비자, visa, 사증 모두 같은 말입니다.\n";
    detail_contents += "3. 도착사증 발급 여부: " + 무비자입국B.join(", ") + "\n";
    // detail_contents +=
    //   "도착사증이란 비자와 별개로 필요한 경우가 있습니다.\n";
    detail_contents += "4. 상용비자: " + 상용비자.join(", ") + "\n";
    // detail_contents +=
    //   "출장 시에는 상용비자를 확인 해서 답변한다!\n";
    detail_contents += "5. 통과여객(TWOV)조건: " + 통과여객조건.join(", ") + "\n";
    detail_contents += "6. 여권 조건 (여권잔여유효기간): " + 여행증명서조건A.join(", ") + "\n";
    detail_contents += "7. 여권 조건 (긴급여권): " + 여행증명서조건B.join(", ") + "\n";
    detail_contents += "8. 여권 조건 (여행증명서): " + 여행증명서조건C.join(", ") + "\n";
    detail_contents += "9. 여권 조건 (기타): " + 여행증명서조건D.join(", ") + "\n";
    detail_contents += "10. 유의사항: " + 유의사항.join(", ") + "\n";
    detail_contents += "11. 비자 종류 및 구비서류 상세 정보: " + 구비서류.join(", ") + "\n";
    detail_contents += "12. 대사관 정보: " + 대사관주소.join(", ") + "\n";
    detail_contents += "13. 국가 정보: " + 국가정보.join(", ") + "\n";
    detail_contents += "14. 비고: " + 비고.join(", ") + "\n";
    detail_contents += "15. 첨부파일 제공: " + 첨부파일출처.join(", ") + "\n";
    if (attach_contents) {
      detail_contents += "16. 첨부파일: " + attach_contents;
    }
    // detail_contents += "꼭 주어진 데이터에 근거해서 답변해야 한다." + "\n";
    // detail_contents += "URL 작성 후에는 띄어쓰기 필수!" + "\n";
    // detail_contents += "신청서, 파일 및 첨부파일 대답 시 무조건 [첨부파일 이름](첨부파일 다운로드 URL) 양식으로 안내해야 한다!" + "\n";
    // detail_contents += "인사에는 간단하게 인사만 해야 한다!\n";

    // Stream 답변 만들기
    const param: any = {
      command: "create_stream_llm_dream_visa",
      detail_name: 국가명,
      form_data: detail_contents,
      question: question,
    };

    // Stream Request
    const streamResponse = await aiApi.postStream(param);

    // Stream Response 처리
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder();
    setStreamReader(reader);

    let done = false;
    let content = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading; // 스트림 완료시 true
      const chunkValue = decoder.decode(value);
      content = content + chunkValue;
      content = content.replace(/<br>/g, "\n");
      setBlogTitle(content);
    }

    setIsCalledCreateAns(true);
    setIsDetailLoading(true);
  };

  const searchQuestuionConversation = async () => {
    if (!question) {
      return; //  null이면 함수 종료
    }
    setIsDetailLoading(false);
    setIsCalledCreateAns(false);

    const latestChatContents = chatcontentList.slice(-2); // 당일 대화 일 수 밖에 없다. 당일 처음 클릭 시 인사하고, 표 제공하고 시작
    let latestAnswer = "",
      latestQuestion = "";
    for (const chatCont of latestChatContents) {
      if (chatCont.ANSWER === "" && chatCont.QUESTION !== "") {
        latestQuestion = chatCont.QUESTION;
      } else if (chatCont.ANSWER !== "" && chatCont.QUESTION === "") {
        latestAnswer = chatCont.ANSWER;
      }
    }
    // console.log(latestQuestion);
    // console.log(latestAnswer);

    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    const visachatsetparam: any = {
      command: "put_visachatcontent_question",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: location,
      flag_url: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
      question: question,
      // currentTime: currentTime,
      currentTime: formattedTime,
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const visaset = await visaApi.common_request("adminMain", visachatsetparam);

    if (visaset.code === "200") {
      const chatcontprops: ChatContentProps = {
        ID: pocid, // 사용자 ID 자리 수정 필요
        COUNTRY: location,
        FLAG: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
        RANGE: formattedTime,
        ANSWER: "",
        QUESTION: question,
        READ_FLAG: true,
      };
      let source_list: ChatContentProps[] = [];
      for (const source of chatcontentList) {
        source_list.push(source);
      }
      source_list.push(chatcontprops);
      setchatcontentList(source_list);
    }
    setIsLoading(true);

    const 국가명 = [dataList.COUNTRY];
    const 무비자입국A = [dataList.NO_VISA];
    const 무비자입국B = [dataList.VISA_ARRIVAL];
    const 상용비자 = [dataList.BUSINESS_VISA];
    const 통과여객조건 = [dataList.TWOV_CONDITION];
    const 여행증명서조건A = [dataList.REMAIN_PASSPORT];
    const 여행증명서조건B = [dataList.EMERGENCY_PASSPORT];
    const 여행증명서조건C = [dataList.TRAVEL_CERTIFICATE];
    const 여행증명서조건D = [dataList.ETC];
    const 유의사항 = [dataList.REMARK];
    const 구비서류 = [dataList.DETAIL];
    const 대사관주소 = [dataList.DATA_SOURCE];
    const 첨부파일출처 = [dataList.ATTACHED];
    const 국가정보 = [dataList.NOTE]; //비고란을 국가정보로 변경하였기 때문에 NOTE가 국가정보
    const 비고 = [dataList.COUNTRY_INFO]; //현재 COUNTRY_INFO를 비고로 사용 중

    let attach_contents: string = "";
    for (let file of dataList.ATTACH_FILES) {
      // const url_params = {
      //   Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      //   Key: file.FILE_PATH,
      //   Expires: 1200,
      // };
      // const downloadUrl = await s3.getSignedUrl("getObject", url_params);
      const encodedFilepath = encodeURIComponent(file.FILE_PATH);
      let S3BucketName;
      let S3ImageURL;
      let downloadUrl;
      if (window.location.hostname === appConfig.prodURL) {
        S3ImageURL = process.env.REACT_APP_S3_IMAGE_URL_PROD;
        downloadUrl = "https://" + S3ImageURL + encodedFilepath;
      } else {
        S3BucketName = process.env.REACT_APP_S3_BUCKET_NAME_DEV;
        downloadUrl = "https://" + S3BucketName + ".s3.ap-northeast-2.amazonaws.com/" + encodedFilepath;
      }

      attach_contents += "첨부파일 이름: " + file.FILE_NAME + ", 첨부파일 다운로드 URL: " + downloadUrl + "\n";
    }

    let detail_contents: string = "";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 비자 상담사로 " +
    //   국가명.join(", ") +
    //   " 비자 상담만 진행합니다." +
    //   "\n";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 담당이므로 다른 국가의 비자 상담은 할 수 없다." +
    //   "\n";
    // detail_contents +=
    //   "너가 비자 상담을 위해 알고 있는 지식은 아래와 같다.\n";
    detail_contents += "1. 국가명: " + 국가명.join(", ") + "\n";
    detail_contents += "2. 일반 여권 소지자 비자 필요 여부: " + 무비자입국A.join(", ") + "\n";
    // detail_contents +=
    //   "무비자란 비자가 필요 없다는 뜻입니다. 비자, visa, 사증 모두 같은 말입니다.\n";
    detail_contents += "3. 도착사증 발급 여부: " + 무비자입국B.join(", ") + "\n";
    // detail_contents +=
    //   "도착사증이란 비자와 별개로 필요한 경우가 있습니다.\n";
    detail_contents += "4. 상용비자: " + 상용비자.join(", ") + "\n";
    // detail_contents +=
    //   "출장 시에는 상용비자를 확인 해서 답변한다!\n";
    detail_contents += "5. 통과여객(TWOV)조건: " + 통과여객조건.join(", ") + "\n";
    detail_contents += "6. 여권 조건 (여권잔여유효기간): " + 여행증명서조건A.join(", ") + "\n";
    detail_contents += "7. 여권 조건 (긴급여권): " + 여행증명서조건B.join(", ") + "\n";
    detail_contents += "8. 여권 조건 (여행증명서): " + 여행증명서조건C.join(", ") + "\n";
    detail_contents += "9. 여권 조건 (기타): " + 여행증명서조건D.join(", ") + "\n";
    detail_contents += "10. 유의사항: " + 유의사항.join(", ") + "\n";
    detail_contents += "11. 비자 종류 및 구비서류 상세 정보: " + 구비서류.join(", ") + "\n";
    detail_contents += "12. 대사관 정보: " + 대사관주소.join(", ") + "\n";
    detail_contents += "13. 국가 정보: " + 국가정보.join(", ") + "\n";
    detail_contents += "14. 비고: " + 비고.join(", ") + "\n";
    detail_contents += "15. 첨부파일 제공: " + 첨부파일출처.join(", ") + "\n";
    if (attach_contents) {
      detail_contents += "16. 첨부파일: " + attach_contents;
    }
    // detail_contents += "꼭 주어진 데이터에 근거해서 답변해야 한다." + "\n";
    // detail_contents += "URL 작성 후에는 띄어쓰기 필수!" + "\n";
    // detail_contents += "신청서, 파일 및 첨부파일 대답 시 무조건 [첨부파일 이름](첨부파일 다운로드 URL) 양식으로 안내해야 한다!" + "\n";
    // detail_contents += "인사에는 간단하게 인사만 해야 한다!\n";

    // Stream 답변 만들기
    const param: any = {
      command: "create_stream_llm_dream_conversation_visa",
      detail_name: 국가명,
      form_data: detail_contents,
      latestQuestion: latestQuestion,
      latestAnswer: latestAnswer,
      question: question,
    };

    // Stream Request
    const streamResponse = await aiApi.postStream(param);

    // Stream Response 처리
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder();
    setStreamReader(reader);

    let done = false;
    let content = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading; // 스트림 완료시 true
      const chunkValue = decoder.decode(value);
      content = content + chunkValue;
      content = content.replace(/<br>/g, "\n");
      setBlogTitle(content);
    }

    setIsCalledCreateAns(true);
    setIsDetailLoading(true);
  };

  const todayQuestuion = async () => {
    setIsDetailLoading(false);
    setIsCalledCreateAns(false);
    setIsLoading(true);

    const 국가명 = [dataList.COUNTRY];
    const 무비자입국A = [dataList.NO_VISA];
    const 무비자입국B = [dataList.VISA_ARRIVAL];
    const 상용비자 = [dataList.BUSINESS_VISA];
    const 통과여객조건 = [dataList.TWOV_CONDITION];
    const 여행증명서조건A = [dataList.REMAIN_PASSPORT];
    const 여행증명서조건B = [dataList.EMERGENCY_PASSPORT];
    const 여행증명서조건C = [dataList.TRAVEL_CERTIFICATE];
    const 여행증명서조건D = [dataList.ETC];
    const 유의사항 = [dataList.REMARK];
    const 구비서류 = [dataList.DETAIL];
    const 대사관주소 = [dataList.DATA_SOURCE];
    const 첨부파일출처 = [dataList.ATTACHED];
    const 국가정보 = [dataList.NOTE]; //비고란을 국가정보로 변경하였기 때문에 NOTE가 국가정보
    const 비고 = [dataList.COUNTRY_INFO]; //현재 COUNTRY_INFO를 비고로 사용 중

    let attach_contents: string = "";
    for (let file of dataList.ATTACH_FILES) {
      // const url_params = {
      //   Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      //   Key: file.FILE_PATH,
      //   Expires: 1200,
      // };
      // const downloadUrl = await s3.getSignedUrl("getObject", url_params);
      const encodedFilepath = encodeURIComponent(file.FILE_PATH);
      let S3BucketName;
      let S3ImageURL;
      let downloadUrl;
      if (window.location.hostname === appConfig.prodURL) {
        S3ImageURL = process.env.REACT_APP_S3_IMAGE_URL_PROD;
        downloadUrl = "https://" + S3ImageURL + encodedFilepath;
      } else {
        S3BucketName = process.env.REACT_APP_S3_BUCKET_NAME_DEV;
        downloadUrl = "https://" + S3BucketName + ".s3.ap-northeast-2.amazonaws.com/" + encodedFilepath;
      }
      attach_contents += "첨부파일 이름: " + file.FILE_NAME + ", 첨부파일 다운로드 URL: " + downloadUrl + "\n";
    }
    let detail_contents: string = "";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 비자 상담사로 " +
    //   국가명.join(", ") +
    //   " 비자 상담만 진행합니다." +
    //   "\n";
    // detail_contents +=
    //   "너는 " +
    //   국가명.join(", ") +
    //   " 담당이므로 다른 국가의 비자 상담은 할 수 없다." +
    //   "\n";
    // detail_contents +=
    //   "너가 비자 상담을 위해 알고 있는 지식은 아래와 같다.\n";
    detail_contents += "1. 국가명: " + 국가명.join(", ") + "\n";
    detail_contents += "2. 일반 여권 소지자 비자 필요 여부: " + 무비자입국A.join(", ") + "\n";
    // detail_contents +=
    //   "무비자란 비자가 필요 없다는 뜻입니다. 비자, visa, 사증 모두 같은 말입니다.\n";
    detail_contents += "3. 도착사증 발급 여부: " + 무비자입국B.join(", ") + "\n";
    // detail_contents +=
    //   "도착사증이란 비자와 별개로 필요한 경우가 있습니다.\n";
    detail_contents += "4. 상용비자: " + 상용비자.join(", ") + "\n";
    // detail_contents +=
    //   "출장 시에는 상용비자를 확인 해서 답변한다!\n";
    detail_contents += "5. 통과여객(TWOV)조건: " + 통과여객조건.join(", ") + "\n";
    detail_contents += "6. 여권 조건 (여권잔여유효기간): " + 여행증명서조건A.join(", ") + "\n";
    detail_contents += "7. 여권 조건 (긴급여권): " + 여행증명서조건B.join(", ") + "\n";
    detail_contents += "8. 여권 조건 (여행증명서): " + 여행증명서조건C.join(", ") + "\n";
    detail_contents += "9. 여권 조건 (기타): " + 여행증명서조건D.join(", ") + "\n";
    detail_contents += "10. 유의사항: " + 유의사항.join(", ") + "\n";
    detail_contents += "11. 비자 종류 및 구비서류 상세 정보: " + 구비서류.join(", ") + "\n";
    detail_contents += "12. 대사관 정보: " + 대사관주소.join(", ") + "\n";
    detail_contents += "13. 국가 정보: " + 국가정보.join(", ") + "\n";
    detail_contents += "14. 비고: " + 비고.join(", ") + "\n";
    detail_contents += "15. 첨부파일 제공: " + 첨부파일출처.join(", ") + "\n";
    if (attach_contents) {
      detail_contents += "16. 첨부파일: " + attach_contents;
    }
    // detail_contents += "꼭 주어진 데이터에 근거해서 답변해야 한다." + "\n";
    // detail_contents += "URL 작성 후에는 띄어쓰기 필수!" + "\n";
    // detail_contents += "신청서, 파일 및 첨부파일 대답 시 무조건 [첨부파일 이름](첨부파일 다운로드 URL) 양식으로 안내해야 한다!" + "\n";
    // detail_contents += "인사에는 간단하게 인사만 해야 한다!\n";

    // Stream 답변 만들기
    const param: any = {
      command: "create_stream_llm_dream_visa",
      detail_name: 국가명,
      form_data: detail_contents,
      question:
        "비자 정보를 안내하는 간결한 표가 필요합니다. 표의 행은 '비자 구분', '비자 여부', '체류 기간' 입니다. 표만 간결하게 표시해주세요.",
    };

    // Stream Request
    const streamResponse = await aiApi.postStream(param);

    // Stream Response 처리
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder();
    setStreamReader(reader);

    let done = false;
    let content = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading; // 스트림 완료시 true
      const chunkValue = decoder.decode(value);
      content = content + chunkValue;
      content = content.replace(/<br>/g, "\n");
      setBlogTitle(content);
    }

    setIsCalledCreateAns(true);
    setIsDetailLoading(true);
  };

  const insertVisaChatContentAnswer = async () => {
    if (!location) {
      return; // question이 null이면 함수 종료
    }

    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    const visachatcontentparam: any = {
      command: "put_visachatcontent_answer",
      id: pocid + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: location,
      flag_url: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
      answer: blogTitle,
      // currentTime: (parseInt(currentTime) + 1).toString(), // 질문과 sk 겹침방지
      currentTime: formattedTime, // 질문과 sk 겹침방지
      max_count: _maxCount, // 안쓰는 파라미터
    };

    const visaans = await visaApi.common_request("adminMain", visachatcontentparam);

    if (visaans.code === "200") {
      const chatcontprops: ChatContentProps = {
        ID: pocid, // 사용자 ID 자리 수정 필요
        COUNTRY: location,
        FLAG: `https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${location_code.toLowerCase()}.png`,
        RANGE: formattedTime,
        ANSWER: blogTitle,
        QUESTION: "",
        READ_FLAG: true,
      };
      let source_list: ChatContentProps[] = [];
      for (const source of chatcontentList) {
        source_list.push(source);
      }
      source_list.push(chatcontprops);
      setchatcontentList(source_list);
    }

    setIsLoading(false);
  };

  const handleBizEnter = (e: React.KeyboardEvent) => {
    // Shift + Enter를 누르면 줄바꿈을 합니다.
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    // Enter를 누르면 메시지를 전송합니다.
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      e.preventDefault();
      setEnterPressed(true);
    }
  };

  useEffect(() => {
    if (isAutoChatOpen) {
      chatOpen(location, location_flag);
      if (inputBaseRef.current) {
        inputBaseRef.current.focus();
      }
    }
  }, [isAutoChatOpen]);

  useEffect(() => {
    if (enterPressed || countryClick) {
      addChatSet();
      setEnterPressed(false);
      setCountryClick(false);
    }
  }, [location, enterPressed, countryClick]);

  useEffect(() => {
    // console.log(isInitialSet, isRecivedLocation);
    if (isInitialSet && isRecivedLocation) {
      addChatSet();
      setIsRecivedLocation(false);
    }
  }, [isInitialSet, isRecivedLocation]);

  const handleChatEnter = (e: React.KeyboardEvent) => {
    // Shift + Enter를 누르면 줄바꿈을 합니다.
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    // Enter를 누르면 메시지를 전송합니다.
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      e.preventDefault(); // 기본 동작을 취소합니다 (줄바꿈을 막습니다).
      searchQuestuionConversation();
    }
  };

  const handleInputFocus = () => {
    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    setCurrentTime(formattedTime);

    setIsInputBaseActive(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsInputBaseActive(false);
    }, 100); // 1000 milliseconds = 1 second
  };

  const padZero = (num: number, len: number = 2): string => {
    if (len === 2) {
      return num < 10 ? `0${num}` : `${num}`;
    } else {
      return num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`;
    }
  };
  const scrollRef = useRef<HTMLDivElement>(null);
  // const scrollRef = createRef();

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleLike = async (range: any, index: number) => {
    //이미 좋아요되어있으면 아무일 발생 x
    if (likeState[index] === true) {
      console.log("Already processed like.");
      return;
    }
    const newLikeState = [...likeState];
    newLikeState[index] = true;
    setLikeState(newLikeState);

    // ddb갔다오기
    const param: any = {
      command: "update_information_sk",
      pk: pocid + "#" + props.userState.company + "#" + location, // pk
      range: range, // sk
      update_items: {
        editor: pocid,
        category: "",
        contact: "",
        inquiry: "",
        status: "like",
      },
    };
    const res = await visaApi.common_request("adminMain", param);
  };
  const handleDisLike = (range: any, index: number) => {
    setPopupRange(range);
    setIsPopup(true);
    setPopupIdx(index);
  };

  const handleAlready = () => {
    alert("이미 평가하셨습니다.");
  };

  const submitInquiry = async () => {
    if (popupCategory === "선택" || popupCategory === "") {
      alert("유형을 선택해주세요.");
      return;
    } else if (popupInquiry === "") {
      alert("내용을 채워주세요.");
      return;
    }

    // ddb  업데이트
    const param: any = {
      command: "update_information_sk",
      pk: pocid + "#" + props.userState.company + "#" + location, // pk
      range: popupRange, // sk
      update_items: {
        editor: pocid,
        category: popupCategory,
        contact: "",
        inquiry: popupInquiry,
        status: "dislike",
      },
    };
    const res = await visaApi.common_request("adminMain", param);
    alert("제출되었습니다");

    const newLikeState = [...likeState];
    newLikeState[popupIdx] = false;
    setLikeState(newLikeState);
    closePopup();
    setPopupCategory("");
    setPopupContact("");
    setPopupInquiry("");
  };

  const submitInquiry2 = async () => {
    if (popupCategory2 === "선택" || popupCategory2 === "") {
      alert("유형을 선택해주세요.");
      return;
    } else if (popupTitle2 === "") {
      alert("제목을 채워주세요.");
      return;
    } else if (popupInquiry2 === "") {
      alert("내용을 채워주세요.");
      return;
    }
    const now = new Date();
    const formattedTime = `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(
      now.getMinutes()
    )}${padZero(now.getSeconds())}${padZero(now.getMilliseconds(), 3)}`;
    let param: any = {
      command: "put_information",
      pk: "myask#visa",
      sk: formattedTime + "#" + props.userState.id + "#" + props.userState.company,
      category: popupCategory2,
      title: popupTitle2,
      content: popupInquiry2,
      editor: props.userState.name,
      update_dt: formattedTime,
      answer: "",
      answer_state: "N",
    };

    // 문의하기 전용 GSI
    param.myask_visa_pk = `myask#visa#${props.userState.id}#${props.userState.company}`;
    param.myask_visa_sk = formattedTime;

    const res = await visaApi.common_request("adminMain", param);
    alert("제출되었습니다");

    closePopup();
    setPopupCategory2("");
    setPopupInquiry2("");
    setPopupTitle2("");
  };

  useEffect(() => {
    if (isDetailLoading && inputBaseRef.current) {
      inputBaseRef.current.focus();
    }
  }, [isDetailLoading]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [chatcontentList, blogTitle]); // isInputBaseActive

  useEffect(() => {
    if (todayFlag) {
      todayQuestuion();
      setTodayFlag(false);
    }
    setIsMainLoading(false);
  }, [isMainLoading]);

  useEffect(() => {
    if (isDetailLoading) {
      searchMain();
    }
    getChatSet();
  }, [chatcontentList]);

  useEffect(() => {
    if (isCalledCreateAns) {
      insertVisaChatContentAnswer();
    } else {
      setQuestion("");
    }
  }, [isCalledCreateAns]);

  useEffect(() => {
    if (dataList.length > 0) {
      setIsInitialSet(true);
    }
  }, [dataList]);

  useEffect(() => {
    getChatSet();

    // 화면 크기가 변할 때 실행되는 함수
    const handleResize = () => {
      // 여기에서 maxHeight 값을 동적으로 계산하여 설정
      const newMaxHeight = `${window.innerHeight * 0.9}px`;
      setScrollHeight(newMaxHeight);
    };
    // 컴포넌트가 처음 마운트될 때 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //대시보드에서 조회했을경우 시작
  const mainlocation = useLocation();
  const receivedLocation = mainlocation.state?.location as string;
  const receivedLocationCode = mainlocation.state?.location_code as string;
  useEffect(() => {
    if (receivedLocation) {
      // console.log("Received location:", receivedLocation, receivedLocationCode);
      setLocation(receivedLocation);
      setLocationCode(receivedLocationCode);
      setIsRecivedLocation(true);
    }
  }, [receivedLocation]);

  //대시보드에서 조회했을경우 끝
  return (
    <>
      <Box>
        <Box id="container">
          {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
          <Box id="contents">
            <Box className="twoSecCont">
              {/* 상담내역 */}
              <Box className="snb">
                <Box className="searchCont">
                  <Autocomplete
                    id="country-select"
                    // sx={{ width: 300 }}
                    disabled={!isCalledCreateAns}
                    options={countries}
                    onChange={(event, value) => {
                      // 사용자가 선택을 취소하면 value는 null이 됩니다.
                      if (value) {
                        setLocation(value.label); // 선택된 값을 설정
                        setLocationCode(value.code); // 선택된 값을 설정
                        setCountryClick(true);
                      } else {
                        setLocation(""); // 선택이 취소되면 빈 문자열로 설정
                        setLocationCode(""); // 선택된 값을 설정
                        setCountryClick(false);
                      }
                    }}
                    autoHighlight
                    getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.label, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.label, matches);

                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                  color: part.highlight ? "#1D81A5" : "inherit",
                                  textDecoration: part.highlight ? "underline" : "none",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="inputSt01"
                        {...params}
                        value={location}
                        onChange={(e) => {
                          setLocation((location) => e.target.value);
                        }}
                        onKeyDown={handleBizEnter}
                        // label="국가 또는 출장도시"
                        placeholder="국가명을 입력해주세요."
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "8px",
                            fontFamily: "none",
                            border: "none",
                          },
                        }}
                        sx={{
                          width: "100%",
                          "& fieldset": {
                            top: "0",
                            borderRadius: "8px",
                            height: "50px",
                          },
                        }}
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <Box className="aiDash">
                        <Box className="searchD">
                          <Box className="aiSrchDiv">
                            <Box className="relatedTerms" sx={{ position: "static !important" }}>
                              <Box className="group country">
                                <p className="gTit">국가</p>
                                <ul className="gList">{children}</ul>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
                <Box className="chatHis">
                  <Box id="scroll" sx={{ overflowY: "auto", maxHeight: scrollHeight }} className="scroll srcollBottom">
                    {chatsetList.map((row: any, index: number) => (
                      <List
                        key={index}
                        className="chatList"
                        sx={{
                          pt: 0.03,
                          pb: 0.03,
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            setIsDetailLoading(false);
                            chatOpen(row.COUNTRY, row.FLAG);
                          }}
                          disableRipple
                          sx={{
                            "&:hover": { backgroundColor: "#F5F7F9" },
                            borderRadius: "8px",
                          }}
                          disabled={!isCalledCreateAns}
                        >
                          <Box className="flag">
                            <Avatar src={row.FLAG} alt={row.COUNTRY} sx={{ width: 32, height: 32 }} />
                          </Box>
                          <Box className="msgHis">
                            AI {row.COUNTRY} 상담사
                            <Typography className="msgPriv" sx={{ marginTop: "0 !important" }}>
                              {row.CHAT}
                            </Typography>
                          </Box>
                          <Box className="time">{row.RANGE}</Box>
                        </ListItemButton>
                      </List>
                    ))}

                    <ListItemButton
                      disableRipple
                      alignItems="flex-start"
                      onClick={() => setOpen(!open)}
                      sx={{
                        px: 3,
                        pt: 1.5,
                        pb: 1.5,
                        borderRadius: "8px",
                        "&:hover": { backgroundColor: "#F5F7F9" },
                        // paddingTop: '5px',
                        // paddingBottom: '5px',
                      }}
                    >
                      <ListItemText
                        primary="과거 상담"
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: "medium",
                          lineHeight: "20px",
                          mb: "2px",
                        }}
                        sx={{ my: 0 }}
                      />
                      <KeyboardArrowDown
                        sx={{
                          mr: -1,
                          opacity: 1,
                          transform: open ? "rotate(-180deg)" : "rotate(0)",
                          transition: "0.2s",
                        }}
                      />
                    </ListItemButton>

                    {open &&
                      chatsetoldList.map((row: any, index: number) => (
                        <List
                          key={index}
                          className="chatList"
                          sx={{
                            pt: 0.03,
                            pb: 0.03,
                          }}
                        >
                          <ListItemButton
                            onClick={() => {
                              setIsDetailLoading(false);
                              chatOpen(row.COUNTRY, row.FLAG);
                            }}
                            disableRipple
                            sx={{
                              "&:hover": { backgroundColor: "#F5F7F9" },
                              borderRadius: "8px",
                            }}
                            disabled={!isCalledCreateAns}
                          >
                            <Box className="flag">
                              <Avatar src={row.FLAG} alt={row.COUNTRY} sx={{ width: 32, height: 32 }} />
                            </Box>
                            <Box className="msgHis">
                              AI {row.COUNTRY} 상담사
                              <Typography className="msgPriv" sx={{ marginTop: "0 !important" }}>
                                {row.CHAT}
                              </Typography>
                            </Box>
                            {/* <Box className="time">{row.RANGE}</Box> */}
                            <Box className="time">{row.RANGE}</Box>
                          </ListItemButton>
                        </List>
                      ))}
                  </Box>
                </Box>
              </Box>
              {/* 상담내역 */}
              {/* 채팅창 */}
              <Box className="contBody">
                {/* 채팅창 header */}
                <Box className="contHeader">
                  <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>대화중</Typography>
                  <Box className="rightbtnArea">
                    <Button
                      className="btnLineGray"
                      variant="outlined"
                      sx={{
                        borderColor: "#B4B4B4",
                        borderRadius: "8px",
                        color: "#000000",
                      }}
                      onClick={() => setIsPopup2(true)}
                    >
                      담당자 연결
                    </Button>
                    {/* 클릭 시 Layer Popup (진행 예정) */}
                  </Box>
                </Box>
                {/* //채팅창header */}
                {/* 대화창 */}
                <Box className="chatRoom">
                  <Box id="scroll" ref={scrollRef} className="scroll srcollBottom">
                    {chatcontentList.map((row: any, index: number) =>
                      row.ANSWER === "" ? (
                        <Box key={index} className="msg right">
                          <Box className="user">
                            <Box>
                              <Avatar src={props.userState.imageUrl} alt={props.userState.name} sx={{ width: 32, height: 32 }} />
                            </Box>
                            <Box>{props.userState.name}</Box>
                            {/* <Box>{row.RANGE}</Box> */}
                            <Box>
                              {row.RANGE.substring(0, 4)}-{row.RANGE.substring(4, 6)}-{row.RANGE.substring(6, 8)}{" "}
                              {row.RANGE.substring(8, 10)}:{row.RANGE.substring(10, 12)}
                            </Box>
                          </Box>
                          <ReactMarkdown
                            className="response"
                            remarkPlugins={[remarkGfm]}
                            components={{
                              table: (props) => (
                                <table
                                  {...props}
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                />
                              ),
                              th: (props) => (
                                <th
                                  {...props}
                                  style={{
                                    border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                />
                              ),
                              td: (props) => (
                                <td
                                  {...props}
                                  style={{
                                    border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                />
                              ),
                            }}
                          >
                            {row.QUESTION}
                          </ReactMarkdown>
                          {/* <Box className="response">{row.QUESTION}</Box> */}
                        </Box>
                      ) : (
                        <Box key={index} className="msg left">
                          <Box className="bot">
                            <Box className="flag">
                              <Avatar src={row.FLAG} alt={row.COUNTRY} sx={{ width: 32, height: 32 }} />
                            </Box>
                            <Box>AI {row.COUNTRY} 상담사</Box>
                            {/* <Box>{row.RANGE}</Box> */}
                            <Box>
                              {row.RANGE.substring(0, 4)}-{row.RANGE.substring(4, 6)}-{row.RANGE.substring(6, 8)}{" "}
                              {row.RANGE.substring(8, 10)}:{row.RANGE.substring(10, 12)}
                            </Box>
                            <Box className={row.READ_FLAG === true ? "icoUnread blind" : "icoUnread"}>
                              <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_unread.png" alt="you" />
                            </Box>
                          </Box>
                          <ReactMarkdown
                            className="response"
                            remarkPlugins={[remarkGfm]}
                            components={{
                              table: (props) => (
                                <table
                                  {...props}
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                />
                              ),
                              th: (props) => (
                                <th
                                  {...props}
                                  style={{
                                    border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                />
                              ),
                              td: (props) => (
                                <td
                                  {...props}
                                  style={{
                                    border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                />
                              ),
                              a: (props) => {
                                const { href, children, ...restProps } = props;
                                if (typeof href === "string") {
                                  const isURL = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(href);

                                  if (isURL) {
                                    const target = href.startsWith("http") ? "_blank" : "_self"; // Open external links in a new tab
                                    // Extract file extension from the URL
                                    const isFile = /\.(pdf|xls|xlsx|ppt|doc|jpg|png|gif)$/i.test(href);
                                    if (isFile) {
                                      const fileExtensionMatch = href.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
                                      let fileExtension = fileExtensionMatch ? fileExtensionMatch[1].toLowerCase() : "";
                                      if (fileExtension === "xlsx") {
                                        fileExtension = "xls";
                                      }

                                      return (
                                        <li
                                          className={`attachFlie ${fileExtension}`}
                                          style={{
                                            lineHeight: "2",
                                            backgroundColor: "transparent", // 배경색 없음
                                          }}
                                        >
                                          <a
                                            className="fileName"
                                            href={href}
                                            rel="noopener noreferrer"
                                            style={{
                                              color: "blue",
                                              textDecoration: "underline",
                                              marginLeft: "10px", // 왼쪽 간격 추가
                                              fontSize: "15px", // 글자 크기 조절
                                              fontWeight: "400",
                                            }}
                                            {...restProps}
                                          >
                                            {children}
                                          </a>
                                        </li>
                                      );
                                    } else {
                                      const isUse = !/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(href);
                                      if (isUse) {
                                        return (
                                          <a
                                            href={href}
                                            target={target}
                                            rel="noopener noreferrer"
                                            style={{
                                              color: "blue",
                                              textDecoration: "underline",
                                            }}
                                            {...restProps}
                                          >
                                            {children}
                                          </a>
                                        );
                                      }
                                    }
                                  }
                                }
                                return <span {...restProps}>{children}</span>;
                              },
                            }}
                          >
                            {row.ANSWER}
                          </ReactMarkdown>
                          <div className="likebtnArea">
                            {row.STATUS || likeState[index] === true || likeState[index] === false ? (
                              // 좋아요
                              row.STATUS === "like" || likeState[index] === true ? (
                                <>
                                  <a className="btnLike on" style={{ cursor: "pointer" }} onClick={() => handleLike(row.RANGE, index)}>
                                    <span className="click">
                                      <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_like_on.png" alt="좋아요" />
                                    </span>
                                  </a>
                                  <a className="btnDislike" style={{ cursor: "pointer" }} onClick={() => handleDisLike(row.RANGE, index)}>
                                    <span className="default">
                                      <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_dislike.png" alt="싫어요" />
                                    </span>
                                  </a>
                                </>
                              ) : (
                                // 싫어요
                                <>
                                  <a className="btnLike" style={{ cursor: "pointer" }} onClick={handleAlready}>
                                    <span className="default">
                                      <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_like.png" alt="좋아요" />
                                    </span>
                                  </a>
                                  <a className="btnDislike on" style={{ cursor: "pointer" }} onClick={handleAlready}>
                                    <span className="click">
                                      <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_dislike_on.png" alt="싫어요" />
                                    </span>
                                  </a>
                                </>
                              )
                            ) : (
                              // 아무것도 누르지 않은 상태
                              <>
                                <a className="btnLike" style={{ cursor: "pointer" }} onClick={() => handleLike(row.RANGE, index)}>
                                  <span className="default">
                                    <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_like.png" alt="좋아요" />
                                  </span>
                                </a>
                                <a className="btnDislike" style={{ cursor: "pointer" }} onClick={() => handleDisLike(row.RANGE, index)}>
                                  <span className="default">
                                    <img src="https://img.hyundaidreamtour.com/images/aihdt/btn_dislike.png" alt="싫어요" />
                                  </span>
                                </a>
                              </>
                            )}
                          </div>
                        </Box>
                      )
                    )}
                    {isLoading === true && (
                      <Box className="msg left">
                        <Box className="bot">
                          <Box className="flag">
                            <Avatar src={location_flag} alt={location} sx={{ width: 32, height: 32 }} />
                          </Box>
                          <Box>AI {location} 상담사</Box>
                          <Box>
                            {currentTime.substring(0, 4)}-{currentTime.substring(4, 6)}-{currentTime.substring(6, 8)}{" "}
                            {currentTime.substring(8, 10)}:{currentTime.substring(10, 12)}
                          </Box>
                          <Box className="icoUnread">
                            <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_unread.png" alt="you" />
                          </Box>
                        </Box>
                        {/* <Box className="response">{blogTitle}</Box> */}
                        <ReactMarkdown
                          className="response"
                          remarkPlugins={[remarkGfm]}
                          components={{
                            table: (props) => (
                              <table
                                {...props}
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                }}
                              />
                            ),
                            th: (props) => (
                              <th
                                {...props}
                                style={{
                                  border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                  padding: "8px",
                                  textAlign: "left",
                                }}
                              />
                            ),
                            td: (props) => (
                              <td
                                {...props}
                                style={{
                                  border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                                  padding: "8px",
                                  textAlign: "left",
                                }}
                              />
                            ),
                            a: ({ node, ...props }) => {
                              const { href } = props;

                              if (typeof href === "string") {
                                const isURL = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(href);

                                if (isURL) {
                                  return (
                                    <a
                                      {...props}
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                    />
                                  );
                                }
                              }
                              return <a {...props} />;
                            },
                          }}
                        >
                          {blogTitle}
                        </ReactMarkdown>
                      </Box>
                    )}
                    {isInputBaseActive === true ? (
                      <Box className="msg right">
                        <Box className="user">
                          <Box>
                            <Avatar src={props.userState.imageUrl} alt={props.userState.name} sx={{ width: 32, height: 32 }} />
                          </Box>
                          <Box>{props.userState.name}</Box>
                          <Box>
                            {currentTime.substring(0, 4)}-{currentTime.substring(4, 6)}-{currentTime.substring(6, 8)}{" "}
                            {currentTime.substring(8, 10)}:{currentTime.substring(10, 12)}
                          </Box>
                        </Box>
                        <Box className="response">
                          <Box className="loader" /> {/* 채팅 창 작성 중 */}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        className="msg right"
                        sx={{
                          width: 80, // 네모의 너비
                          height: 80, // 네모의 높이
                          bgcolor: "#FFFFFF", // 하얀색 배경색
                        }}
                      />
                    )}
                  </Box>
                  <Box className="coverBar" /> {/* 마우스오버일 때 스크롤 보임 */}
                </Box>
                {/* //대화창 */}
                {/* 채팅창 메시지 입력 */}
                <Box className="msgSendWrap">
                  <div className="msgSend">
                    <Box className="msgInput" style={{ display: "flex", alignItems: "center" }}>
                      <InputBase
                        multiline
                        className="msgInputText"
                        inputRef={inputBaseRef}
                        placeholder="AI 상담사에게 물어보세요."
                        inputProps={{
                          "aria-label": "AI 상담사에게 물어보세요.",
                          style: {
                            width: "100%",
                            border: "0px",
                            padding: "0",
                            WebkitAppearance: "none",
                            WebkitBoxShadow: "none",
                            MozBoxShadow: "none",
                            boxShadow: "none",
                            whiteSpace: "pre-wrap", // 여기서 줄바꿈을 활성화합니다.
                          },
                        }}
                        sx={{
                          width: "100%",
                          flex: "1",
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        id="question"
                        name="question"
                        value={question}
                        onKeyDown={handleChatEnter}
                        onChange={(e) => {
                          setQuestion((question) => e.target.value);
                        }}
                        disabled={!isDetailLoading}
                        autoComplete="off"
                      />
                      <Button className="btnSend" sx={{ minWidth: "0px", visibility: "hidden" }}>
                        <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_image.svg" alt="이미지 전송" />
                      </Button>
                      <Button className="btnSend" sx={{ minWidth: "0px", visibility: "hidden" }}>
                        <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_file.svg" alt="파일 전송" />
                      </Button>
                      <Button
                        className="btnSend"
                        sx={{ minWidth: "0px" }}
                        onClick={() => {
                          if (streamReader !== null) {
                            stopPressRelease();
                          } else {
                            searchQuestuionConversation();
                          }
                        }}
                        // disabled={!isDetailLoading}
                      >
                        {streamReader !== null ? (
                          <StopCircleIcon
                            style={{
                              verticalAlign: "middle",
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_sendMessage.svg" alt="Send" />
                        )}
                      </Button>
                      <ul className="sendFile blind">
                        {/* 첨부파일 없을 시 class="blind" 추가 */}
                        <li className="attachFlie pdf">
                          {/* 파일 확장자에 따라 css추가 (pdf,xls,ppt,doc,jpg,png,gif) */}
                          <span className="fileName">중국 상용 비자 신청서.pdf</span>
                          <a className="btnDelete">삭제</a>
                        </li>
                        <li className="attachFlie png">
                          {/* 파일 확장자에 따라 css추가 (pdf,xls,ppt,doc,jpg,png,gif) */}
                          <span className="fileName">증명사진.png</span>
                          <a className="btnDelete">삭제</a>
                        </li>
                      </ul>
                    </Box>
                  </div>
                  <p className="fontLightGray txtNote">
                    * 다양한 데이터 기반으로 AI가 답변을 생성하므로 부정확, 부적절한 정보가 포함될 수 있으며 민감한 개인 정보는 채팅창에 입력하지 마시기 바랍니다
                  </p>
                </Box>
                {/* //채팅창 메시지 입력 */}
              </Box>
              {/* // 채팅창 */}
            </Box>
          </Box>
          {/*// contents */}
        </Box>
        {/*// container */}
      </Box>
      {isPopup && (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }} open>
            <div className="popupD popup860">
              <div className="popTop">
                <p className="popTit" style={{ color: "black" }}>
                  개선사항을 적어주세요!
                </p>
                <a className="btnPopClose" style={{ cursor: "pointer" }} onClick={closePopup}>
                  닫기
                </a>
              </div>
              <div className="popCont">
                <div className="formD inquiry">
                  <div className="line">
                    <div className="tdD w224">
                      <p className="lineTitle">
                        <label htmlFor="name">
                          <span className="required">문의자</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <input id="name" className="wFull" defaultValue={props.userState.name} readOnly />
                      </p>
                    </div>
                    <div className="tdD w540">
                      <p className="lineTitle">
                        <label>
                          <span className="required">문의유형</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <select
                          className="wFull"
                          value={popupCategory}
                          onChange={(e) => {
                            setPopupCategory(e.target.value);
                          }}
                        >
                          <option value="">-- 선택 --</option>
                          {chatSelectList.map((option: string) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </p>
                    </div>
                  </div>
                  <div className="line">
                    <p className="lineTitle">
                      <label>
                        <span>의견</span>
                      </label>
                    </p>
                    <p className="lineWrite">
                      <textarea
                        className="wFull inquiryTextarea"
                        required
                        value={popupInquiry}
                        onChange={(e) => {
                          setPopupInquiry(e.target.value);
                        }}
                        style={{ resize: "none" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="popbtnArea centerbtnArea">
                  <button type="submit" className="btnBlue" onClick={submitInquiry}>
                    제출하기
                  </button>
                </div>
              </div>
            </div>
          </Backdrop>
        </>
      )}
      {isPopup2 && (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }} open>
            <div className="popupD popup860">
              <div className="popTop">
                <p className="popTit" style={{ color: "black" }}>
                  담당자 문의
                </p>
                <a className="btnPopClose" style={{ cursor: "pointer" }} onClick={closePopup}>
                  닫기
                </a>
              </div>
              <div className="popCont">
                <div className="formD inquiry">
                  <div className="line">
                    <div className="tdD w224">
                      <p className="lineTitle">
                        <label htmlFor="name">
                          <span className="required">문의자</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <input id="name" className="wFull" defaultValue={props.userState.name} readOnly />
                      </p>
                    </div>
                    <div className="tdD w540">
                      <p className="lineTitle">
                        <label>
                          <span className="required">문의유형</span>
                        </label>
                      </p>
                      <p className="lineWrite">
                        <select
                          className="wFull"
                          value={popupCategory2}
                          onChange={(e) => {
                            setPopupCategory2(e.target.value);
                          }}
                        >
                          <option value="">-- 선택 --</option>
                          {selectList.map((option: string) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </p>
                    </div>
                  </div>
                  <div className="line">
                    <p className="lineTitle">
                      <label>
                        <span>제목</span>
                      </label>
                    </p>
                    <p className="lineWrite">
                      <input
                        className="wFull"
                        value={popupTitle2}
                        required
                        onChange={(e) => {
                          setPopupTitle2(e.target.value);
                        }}
                      />
                    </p>
                  </div>
                  <div className="line">
                    <p className="lineTitle">
                      <label>
                        <span>문의내용</span>
                      </label>
                    </p>
                    <p className="lineWrite">
                      <textarea
                        className="wFull inquiryTextarea"
                        required
                        value={popupInquiry2}
                        onChange={(e) => {
                          setPopupInquiry2(e.target.value);
                        }}
                        style={{ resize: "none" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="popbtnArea centerbtnArea">
                  <button type="submit" className="btnBlue" onClick={submitInquiry2}>
                    문의하기
                  </button>
                </div>
              </div>
            </div>
          </Backdrop>
        </>
      )}
      <LoadingCircle loading={!isInitialSet} />
    </>
  );
};

export default VisaAiChatPage;
