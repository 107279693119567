import React, { useEffect, useRef, useState } from 'react'
import { userState } from "../../interface/MainInterface";
import { iNotice } from '../../interface/VisaInterface'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import AttachFileViewer from '../../utils/attach/AttachFileViewer';
import NoticeEditor from './NoticeEditor';
import S3RemoveAndUpload from '../../utils/attach/S3RemoveAndUpload';

const visaApi = new HttpCustomApi(
    window.location.hostname === appConfig.prodURL
        ? appConfig.REACT_APP_VISA_API_PROD
        : appConfig.REACT_APP_VISA_API_DEV
);

// s3 다운로드 고정 주소
// const encodedFilepath = encodeURIComponent(file.FILE_PATH);
// const downloadUrl = "https://gdtdev-s3managerstack-visa-info-attach.s3.ap-northeast-2.amazonaws.com/" + encodedFilepath;
//

interface propsType {
    userState: userState;
}

export default function NoticeDetail(props: propsType) {
    const navigate = useNavigate();
    const { sk } = useParams();
    const attachFileViewerRef: any = useRef();
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(true);
    const [notice, setNotice] = useState<iNotice>();

    const [isEditData, setIsEditData] = useState(false);

    const get_information_sk = async () => {
        const param = {
            command: "get_information_sk",
            pk: "notice#visa",
            sk: sk
        }
        const res = await visaApi.common_request("adminMain", param);
        if (res.code === "200") {
            for (const item of res.response.result_list) {
                setNotice(item)
            }
        }
        else if (res.code === "300") {
            console.log(" 300 res.response : ", res.response)
        }
        else if (res.code === "500") {
            console.log(" 500 res.response : ", res.response)
        }
    }

    const handlePrevClick = () => {
        navigate(-1)
    }

    const toggleIsComponetVisible = () => {
        setIsComponentVisible(!isComponentVisible)
    }

    const handlePrint = () => {
        window.print(); // 브라우저 인쇄 다이얼로그 열기
    };

    const deleteNotice = () => {
        if (!window.confirm("정말로 삭제하시겠습니까?\n삭제한 데이터는 복구할 수 없습니다.")) return;
        const ddbDeleted = deleteNoticeDdb()
        if (!ddbDeleted) {
            alert("삭제에 실패하였습니다. \n다시 시도 바랍니다.")
        }
        if (window.location.hostname === appConfig.prodURL) {
            S3RemoveAndUpload(
                [], // 빈배열 보내서 다 삭제.
                `notice/${notice?.sk}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_PROD,
                fncDeleteComplete)
        }
        else {
            S3RemoveAndUpload(
                [], // 빈배열 보내서 다 삭제.
                `notice/${notice?.sk}/`,
                process.env.REACT_APP_S3_BUCKET_NAME_DEV,
                fncDeleteComplete)
        }
    }

    const deleteNoticeDdb = async () => {
        const param: any = {
            command: "delete_information",
            pk: "notice#visa",
            sk: notice?.sk
        };

        const visares = await visaApi.common_request("adminMain", param);
        if (visares.code === "200") {
            return true;
        }
        else {
            return false;
        }
    }

    const fncDeleteComplete = (result: any) => {
        if (result.status === "ok") {
            alert("삭제하였습니다.")
            navigate("/notice")
        }
    };


    useEffect(() => {
        get_information_sk()
    }, [])

    return (
        <>
            <Box id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
                <div id="contents">
                    <div className="secCont board">
                        {isComponentVisible ? (
                            <>
                                <div className="contBody">
                                    <div className="contHeader">
                                        <div className="tit">
                                            <a className="btnPrev" onClick={handlePrevClick} style={{ cursor: "pointer" }}>이전</a>
                                            <p>중요 공지 사항</p>
                                        </div>
                                    </div>
                                    <div className="contBox">
                                        <div className="boardInfo">
                                            <div className="info">
                                            </div>
                                            <div className="rightbtnArea">
                                                <a className="btnLineGray print" onClick={handlePrint} style={{ cursor: 'pointer', }} >인쇄</a>
                                                {/* 수정/삭제 버튼은 작성자, 관리자만 노출 */}
                                                {props.userState.isAdmin || notice?.editor === props.userState.name ?
                                                    <>
                                                        <a className="btnLineGray modify" style={{ cursor: "pointer" }} onClick={toggleIsComponetVisible}>수정</a>
                                                        <a className="btnLineGray delete" style={{ cursor: "pointer" }} onClick={deleteNotice}>삭제</a>
                                                    </> : <></>
                                                }

                                            </div>
                                        </div>
                                        <div className="readD">
                                            <div className="rtitD">
                                                <div className="title">{notice?.title}</div>
                                                <div className="date fontL fontLightGray">등록일: {notice?.sk.substring(0, 4)}-{notice?.sk.substring(4, 6)}-{notice?.sk.substring(6, 8)} <br />
                                                    수정됨: {notice?.update_dt?.substring(0, 4)}-{notice?.update_dt?.substring(4, 6)}-{notice?.update_dt?.substring(6, 8)}</div>

                                            </div>
                                            <div className="rcontD" style={{ whiteSpace: 'pre-wrap' }} >
                                                {notice?.content}
                                            </div>
                                            {(window.location.hostname === appConfig.prodURL) ? (
                                                <AttachFileViewer
                                                    ref={attachFileViewerRef}
                                                    rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_PROD}
                                                    files={notice?.attach_files}
                                                    canDelete={isEditData ? true : false}
                                                    isBox={false}
                                                    callback=''
                                                />
                                            ) : (
                                                <AttachFileViewer
                                                    ref={attachFileViewerRef}
                                                    rootBucket={process.env.REACT_APP_S3_BUCKET_NAME_DEV}
                                                    files={notice?.attach_files}
                                                    canDelete={isEditData ? true : false}
                                                    isBox={false}
                                                    callback=''
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <NoticeEditor
                                propNotice={notice}
                                onChange={toggleIsComponetVisible}
                                userState={props.userState}
                            />
                        )
                        }
                        {/* // 세부정보 읽기 */}
                    </div>
                </div>{/*// contents */}<br />
            </Box >

        </>
    )
}
