import { Avatar } from "@mui/material";
import { userState } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";
import { HttpCustomApi } from "../../interface/custom-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { countries } from "../components/CountryList";
import { readLocal, saveLocal } from "../../utils/localstorage_utils";

import LoadingCircle from "../../utils/LoadingCircle";

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);
interface propsType {
  userState: userState;
  setLeftUpdate: any;
}
                            // 화면에 보여줄 페이징 수
const NOTICE_PER_PAGE = 10                          // 한 화면에 보여줄 글 개수

const AiDataPage = (props: propsType) => {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [aiDataList, setAiDataList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const [totalCnt, setTotalCnt] = useState(0);
  const [finCnt, setFinCnt] = useState(0);
  const [waitCnt, setWaitCnt] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialSet, setIsInitialSet] = useState(false);

  const getAidataList = async () => {
    let waitList: any = [];
    let finList: any = [];
    let param: any = {
      command: "get_information_proj",
      pk: "rpa#visa",
      projection: 'sk, country, title, day, time, url, prev_status, status, worker, new_content, content, new_content_eng, content_eng'
    };

    let res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      for (let aidata of res.response.result_list) {
        if (aidata.status === "100" || aidata.status === "101" || aidata.status === "102") {
          aidata['approval_yn'] = true;
          if (aidata.status === "100") {
            aidata['linked'] = "연동요청";
          } else if (aidata.status === "101") {
            aidata['linked'] = "수정요청";
          } else {
            aidata['linked'] = "무시요청";
          }
          waitList.push(aidata);
        }
      }
      setWaitCnt(waitList.length)
    }

    param = {
      command: "get_information_proj",
      pk: "data#visa",
      projection: 'sk, country, title, day, time, url, prev_status, status, worker, new_content, content'
    };

    res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      for (let aidata of res.response.result_list) {
        aidata['approval_yn'] = false;
        aidata['linked'] = "";
        finList.push(aidata);
      }
      setFinCnt(finList.length)
    }

    // 정렬
    finList.sort((a: any, b: any) => {
      if (a.day > b.day) return -1;
      if (a.day < b.day) return 1;
      // day가 같다면 time으로 정렬
      if (a.time > b.time) return -1;
      if (a.time < b.time) return 1;
      return 0;
    });
    waitList.sort((a: any, b: any) => {
      if (a.day > b.day) return -1;
      if (a.day < b.day) return 1;
      // day가 같다면 time으로 정렬
      if (a.time > b.time) return -1;
      if (a.time < b.time) return 1;
      return 0;
    });

    const combinedList = [...waitList, ...finList];
    let idx = combinedList.length;
    setTotalCnt(idx);
    for (let aidata of combinedList) {
      aidata['idx'] = idx;
      idx = idx - 1;
    }
    setAiDataList(combinedList);
    setIsUpdating(false);
    setIsInitialSet(true);
  }

  const handleClick = async (item: any, status: string, reFlag: boolean) => {
    if (status === "200") {
      if (!window.confirm("승인하시겠습니까?")) return;
    }
    else if (status === "201") {
      if (!window.confirm("반려하시겠습니까?")) return;
    }
    else {
      if (!window.confirm("회수하시겠습니까?")) return;
    }

    const today: Date = new Date();
    const year: string = today.getFullYear().toString();
    const month: string = (today.getMonth() + 1).toString().padStart(2, '0');
    const day: string = today.getDate().toString().padStart(2, '0');
    const formattedDate: string = `${year}-${month}-${day}`;

    const hours: string = today.getHours().toString().padStart(2, '0');
    const minutes: string = today.getMinutes().toString().padStart(2, '0');
    const formattedTime: string = `${hours}:${minutes}`;

    let param: any = {};
    if (status === "200" || status === "201") {
      param = {
        command: "put_information",
        pk: "data#visa", // pk
        sk: item.sk+"#"+`${year}${month}${day}${hours}${minutes}`, // sk
        country: item.country,
        title: item.title,
        worker: item.worker,
        status: status,
        day: formattedDate,
        time: formattedTime,
        prev_status: item.prev_status,
        content: item.content,
        new_content: item.new_content,
        url: item.url,
      };
      const res = await visaApi.common_request("adminMain", param);
    }

    if (!reFlag && (item.prev_status !== "0")) {
      param = {
        command: "update_information_rpa_sk",
        pk: "rpa#visa", // pk
        sk: item.sk, // sk
        update_items: {
          prev_status: item.prev_status,
          status: status,
          worker: props.userState.name,
          day: formattedDate,
          time: formattedTime,
          content: item.new_content,
          content_eng: item.new_content_eng,
        }
      };
    }
    else if (!reFlag) {
      param = {
        command: "update_information_rpa_sk",
        pk: "rpa#visa", // pk
        sk: item.sk, // sk
        update_items: {
          prev_status: item.prev_status,
          status: status,
          worker: props.userState.name,
          day: formattedDate,
          time: formattedTime,
        }
      };
    }
    else {
      param = {
        command: "update_information_rpa_sk",
        pk: "rpa#visa", // pk
        sk: item.sk, // sk
        update_items: {
          prev_status: item.status,
          status: item.prev_status,
          worker: props.userState.name,
          day: formattedDate,
          time: formattedTime,
        }
      };
    }

    const res = await visaApi.common_request("adminMain", param);
    if (res.code === "200") {
      setIsUpdating(true);
      props.setLeftUpdate(true);
    }
  }

  const filteredAidataList = aiDataList.filter((item: any) => {
    return item.title.toLowerCase().includes(searchKey.toLowerCase()) ||
    item.worker.toLowerCase().includes(searchKey.toLowerCase()) ||
    item.country.toLowerCase().includes(searchKey.toLowerCase());
  });

  // 페이지 변경 핸들러
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (isUpdating) {
      getAidataList();
    }
  }, [isUpdating]);
  useEffect(() => {
    getAidataList()
    const storedData = readLocal(appConfig.aidataSearchKey01)
    const storedPage = readLocal(appConfig.aidataPageKey01)
    if (storedData !== null && storedData !== "") {
      try {
        const recentSearchData = storedData;
        setSearchKey(recentSearchData);
        saveLocal(appConfig.aidataSearchKey01, "");
      } catch (error) {
        console.error("Parsing SearchKey error:", error);
      }
    }
    if (storedPage !== null && storedPage !== "") {
      try {
        const recentPageData = storedPage;
        setCurrentPage(recentPageData);
        saveLocal(appConfig.aidataPageKey01, 1)
      } catch (error) {
        console.error("Parsing PageKey error:", error);
      }
    }
    return () => {
      setSearchKey("");
      setCurrentPage(1);
    };
  }, [])


  return <>
    <div id="container"> {/* GNB가 불필요한 시스템의 경우 class="noneHeader" 추가 */}
      <div id="contents">
        <div className="secCont board">
          {/* 세부정보 읽기 */}
          <div className="contBody">
            <div className="contHeader">
              <div className="tit">
                {/* <a class="btnPrev">이전</a> */}
                <p>데이터 관리</p>
              </div>
              {/*<div class="rightbtnArea">
								<a숮 onclick="" class="btnEmail">이메일 전송</a숮>'
								<a onclick="" class="btnLineGray">수정</a>
								<a onclick="" class="btnLineGray">삭제</a>
							</div>*/}
            </div>
            <div className="contBox">
              <div className="boardInfo">
                <div className="info">
                  <dl className="total number">
                    <dt className="fontGray">Total</dt>
                    <dd className="fontBlue fontB">{totalCnt}</dd>
                  </dl>
                  <dl className="signOff number">
                    <dt className="fontGray">결재완료</dt>
                    <dd className="fontBlue fontB">{finCnt}</dd>
                  </dl>
                  <dl className="signWait number">
                    <dt className="fontGray">결재대기</dt>
                    <dd className="fontBlue fontB">{waitCnt}</dd>
                  </dl>
                </div>
                <div className="rightbtnArea">
                  <input
                    className="search"
                    id="search"
                    name="search"
                    placeholder="검색어를 입력해주세요."
                    value={searchKey}
                    onChange={(e) => { setSearchKey(e.target.value); setCurrentPage(1); }}
                  />
                  {/* <a className="btnLineGray export">Export</a>
                  <a className="btnLineGray refresh">새로고침</a> */}
                </div>
              </div>
              <table className="boardD">
                <colgroup>
                  <col className="num" />
                  <col className="country" />
                  <col className="signtitle" />
                  <col className="see" />
                  <col className="writer" />
                  <col className="sign" />
                  <col className="date" />
                </colgroup>
                <thead>
                  <tr  >
                    <td scope="col">번호</td>
                    <td scope="col">국가</td>
                    <td scope="col">제목</td>
                    <td scope="col">연동상태</td>
                    <td scope="col">연동요청자</td>
                    {props.userState.isSuperAdmin? (
                      <td scope="col">관리자 승인/반려</td>
                    ):(
                      <td scope="col">결재현황</td>
                    )}
                    <td scope="col">등록일</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredAidataList.slice(((currentPage - 1) * NOTICE_PER_PAGE), ((currentPage - 1) * NOTICE_PER_PAGE) + NOTICE_PER_PAGE).map((aidata: any, index: any) => {
                    const foundCountry = countries.find(country => country.label === aidata.country);
                    if (foundCountry) {
                      return (
                        <tr className={aidata.approval_yn ? "bHighlight" : ""} key={index}>
                          <td>{aidata.idx}</td>
                          <td className="bCountry">
                            <img src={`https://img.hyundaidreamtour.com/images/aihdt/flag/flag_${foundCountry.code.toLowerCase()}.png`} alt={aidata.country} />
                            <span>{aidata.country}</span>
                          </td>
                          <td className="boardtit"
                            onClick={() => {
                              saveLocal(appConfig.aidataSearchKey01, searchKey)
                              saveLocal(appConfig.aidataPageKey01, currentPage)
                              navigate(`/aidataRead/${aidata.sk}`, { state: { selectedItem: aidata } })
                          }}>
                            <a >{aidata.country} {aidata.title}</a>
                          </td>
                          <td><span>{aidata.linked}</span></td>{/* 미노출 시 class="blind" 추가 */}
                          <td>{aidata.worker}</td>
                          <td className="signD">
                            {props.userState.isSuperAdmin ? (
                              <div>
                                {aidata.approval_yn ? (
                                  <div className="centerbtnArea" style={{ marginTop: "0px" }}>
                                    <button className="btnCSlim btnBlue" onClick={() => handleClick(aidata, '200', false)}>승인</button>
                                    <button className="btnCSlim btnGray" onClick={() => handleClick(aidata, '201', true)}>반려</button>
                                  </div>
                                ) : (
                                  <p className="signStatus">
                                    {aidata.status === '200' ? (
                                      <span className="fontBlue">승인</span>
                                    ) : aidata.status === '201' ? (
                                      <span className="fontRed">반려</span>
                                    ) : null}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div>
                                {aidata.approval_yn ? (
                                  <p className="centerbtnArea">
                                    <button className="btnCSlim btnGray" onClick={() => handleClick(aidata, '202', true)}>회수</button>
                                  </p>
                                ) : (
                                  <p className="signStatus">
                                    {aidata.status === '200' ? (
                                      <span className="fontBlue">승인</span>
                                    ) : aidata.status === '201' ? (
                                      <span className="fontRed">반려</span>
                                    ) : null}
                                  </p>
                                )}
                              </div>
                            )}
                          </td>
                          <td>{aidata.day}</td>
                        </tr>
                      )
                    }
                    else {
                      return (
                        <tr className={aidata.approval_yn ? "bHighlight" : ""} key={index}>
                          <td>{aidata.idx}</td>
                          <td className="bCountry" style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar style={{ width: 24, height: 24, marginRight: 3 }}>
                              <span style={{ fontSize: 16, alignItems: 'center' }}>{aidata.country.charAt(0)}</span>
                            </Avatar>
                            <span>{aidata.country}</span>
                          </td>
                          <td className="boardtit"
                            onClick={() => {
                              saveLocal(appConfig.aidataSearchKey01, searchKey)
                              saveLocal(appConfig.aidataPageKey01, currentPage)
                              navigate(`/aidataRead/${aidata.sk}`, { state: { selectedItem: aidata } })
                          }}>
                            <a >{aidata.country} {aidata.title}</a>
                          </td>
                          <td><span>{aidata.linked}</span></td>{/* 미노출 시 class="blind" 추가 */}
                          <td>{aidata.worker}</td>
                          <td className="signD">
                            {props.userState.isSuperAdmin ? (
                              <div>
                                {aidata.approval_yn ? (
                                  <div className="centerbtnArea" style={{ marginTop: "0px" }}>
                                    <button className="btnCSlim btnBlue" onClick={() => handleClick(aidata, '200', false)}>승인</button>
                                    <button className="btnCSlim btnGray" onClick={() => handleClick(aidata, '201', true)}>반려</button>
                                  </div>
                                ) : (
                                  <p className="signStatus">
                                    {aidata.status === '200' ? (
                                      <span className="fontBlue">승인</span>
                                    ) : aidata.status === '201' ? (
                                      <span className="fontRed">반려</span>
                                    ) : null}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div>
                                {aidata.approval_yn ? (
                                  <p className="centerbtnArea">
                                    <button className="btnCSlim btnGray" onClick={() => handleClick(aidata, '202', true)}>회수</button>
                                  </p>
                                ) : (
                                  <p className="signStatus">
                                    {aidata.status === '200' ? (
                                      <span className="fontBlue">승인</span>
                                    ) : aidata.status === '201' ? (
                                      <span className="fontRed">반려</span>
                                    ) : null}
                                  </p>
                                )}
                              </div>
                            )}
                          </td>
                          <td>{aidata.day}</td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>

              <div className="pagination">
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                      {...item}
                    />
                  )}
                  size="large"
                  className="page"
                  count={Math.ceil(filteredAidataList.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                />
              </div>

            </div>
          </div>
          {/* // 세부정보 읽기 */}
        </div>
      </div>{/*// contents */}
    </div > {/*// container */}
    <LoadingCircle loading={!isInitialSet} />
  </>;
};

export default AiDataPage;
