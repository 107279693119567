export interface userState {
  id: string;
  name: string;
  company: string;
  isAuth: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean; //결재자: true
  imageUrl: string;
}
export interface IMenuItem {
  name: string;
  iconType: string;
  icon: any;
  path: string;
  adminOnly?: boolean;
  children?: Array<IMenuItem>;
}

export interface DreamMenuItem {
  name: string;
  className: string;
  classNameSelect: string;
  path: string;
  adminOnly?: boolean;
  children?: Array<IMenuItem>;
}

// 로그인
export interface LoginProcParam {
  id: string;
  password: string;
}

export interface FaqProps {
  pk?: any;
  sk?: any;
  title?: any;
  answer?: any;
}

export let aiApiUrlDev =
  "https://qfbg6zbteqbrp6oa5cq6ongdz40rypgx.lambda-url.ap-northeast-2.on.aws/";

export let aiApiUrlProd =
  "https://ai.ai-hyundaidreamtour.com/";
  // "https://c5okbjvc7oszy7phpmuudxfnhm0mfivh.lambda-url.ap-northeast-2.on.aws/";
  // "https://d2d2fnfzpoh01k.cloudfront.net/";

export const themeSeparator = "^";
