import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import { HttpCustomApi } from "../../interface/custom-api";
import { appConfig } from "../../config/Config";

interface propsType {
  itemNum: number;
}

interface SafetyInfoType {
  idx: number;
  country: string;
  range: string;
  title: string;
  content: string;
  attach_files: any;
}

const visaApi = new HttpCustomApi(
  window.location.hostname === appConfig.prodURL
    ? appConfig.REACT_APP_VISA_API_PROD
    : appConfig.REACT_APP_VISA_API_DEV
);

const SafetyInfoComponent = (props: propsType) => {
  const navigate = useNavigate();
  const [safetyList, setSafetyList] = useState<SafetyInfoType[]>([]);
  const [newDate, setNewDate] =  useState("");
  const [itemNum, setItemNum] = useState(props.itemNum);

  const setSafetyInfoList = async () => {
    let _maxCount: number = 3;
    let idx: number = 1;
  
    const param: any = {
      command: "get_information",
      pk: "safety#visa",
      max_count: _maxCount, // 안쓰는 파라미터
    };
  
    const res = await visaApi.common_request("adminMain", param);
  
    if (res.code === "200") {
      const searchResult = res.response.result_list;
      for (const source of searchResult) {
        const match = source['sk'].match(/^(\d{4})(\d{2})(\d{2})/);
        if (match) {
          source['range'] = `${match[1]}-${match[2]}-${match[3]}`;
        }
        source['idx'] = idx;
        idx = idx + 1;
      }
      
      const reversed = searchResult.reverse();

      setNewDate(reversed[0]['range']);
      setSafetyList(reversed);
    }
  };

  useEffect(() => {
    setSafetyInfoList();
  }, []);

  return (
    <div className="noticeD">
      <div className="tit">
        <h2>해외 안전 공지</h2>
        <div className="rightBtnArea">
          <a className="btnMore"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/safetyinfo`)
          }}>
            더보기
          </a>
        </div>
      </div>
      <ul className="noticeList">
        {safetyList.slice(0, itemNum).map((item: any, index) => (
          <li
            key={index}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/safetyNewsListRead/${item.sk}`, {state:{selectedItem:item}})
            }}
          >
            <span className={`label blue ${newDate === item.range ? '' : 'blind'}`}>신규</span>
            <a>{item.title}</a>
            <span className="date">{item.range}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SafetyInfoComponent;
