// localStorage 읽기
export const readLocal = (key: any): any => {
    const settings = localStorage.getItem(key);
    if (settings) {
        return JSON.parse(settings);
    }
    return null;
};

// localStorage 쓰기
export const saveLocal = (key: any, settings: any): void => {
    localStorage.setItem(key, JSON.stringify(settings));
};
